import { useEffect   } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

function PrivateRoute({ children, isAdmin, isAgent }) { 

  const navigate = useNavigate()

  const { isAuthenticated, loading, user } = useSelector( state => state.auth )

  useEffect(() => {

    if (!loading) {

      if (!isAuthenticated) {
        navigate('/login')
      }   

      if (isAdmin && user?.role !== 'admin') {          
        navigate('/')
      }        

      if (isAgent && (user?.role !== 'admin' && user?.role !== 'agent')) {          
        navigate('/')
      } 

    }    

  }, [navigate, loading, isAuthenticated, isAdmin, isAgent, user])

  return (
    <> 
    {!loading && isAuthenticated && (
      children
    )}
    </>
  )

}

export default PrivateRoute

