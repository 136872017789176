import { useAlert } from 'react-alert'
import { useDispatch } from 'react-redux'
import { addMerchItemToCart } from '../../../actions/cartActions'
import ShoppingCartIcon from '../icons/navigation/ShoppingCartIcon'

const AddToCart = ({ slug, quantity, label, className, handleCart, disabled }) => {

    const alert    = useAlert()
    const dispatch = useDispatch()

    const addToCart = () => {
        dispatch(addMerchItemToCart(slug, quantity))
        alert.success('Item added to cart')
        if (handleCart)  handleCart()     
    }

    return (

        <button 
            className={`button booking-button ${className ? className : ''}`} 
            aria-label={label}
            onClick={addToCart}
            disabled={disabled}
        >
            <ShoppingCartIcon style={{ color: 'white' }} aria-label="add to cart" />
            &nbsp;
            {label}
        </button> 

    )

}

export default AddToCart