import { lazy, Suspense, useEffect, useState } from 'react'
import { Routes, Route, Navigate             } from 'react-router-dom'
import { loadUser                            } from './actions/userActions'
import { lazyRetry                           } from './utils'
import store from './store'
import axios from 'axios'
// Payment imports 
import { Elements   } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
// Layout import
import Header       from './components/layout/header/Header'
import Footer       from './components/layout/footer/Footer'
import PrivateRoute from './components/route/PrivateRoute'
import ScrollToTop  from './components/route/ScrollToTop'
import Loader       from './components/layout/tools/Loader'

// Page imports 
const Home              = lazy(() => lazyRetry(() => import('./components/Home')))
const Trips             = lazy(() => lazyRetry(() => import('./components/Trips')))
const TripsList         = lazy(() => lazyRetry(() => import('./components/TripsList')))

const TripItinerary     = lazy(() => lazyRetry(() => import('./components/TripItinerary')))
const Pickup            = lazy(() => lazyRetry(() => import('./components/Pickup')))
const Equipment         = lazy(() => lazyRetry(() => import('./components/Equipment')))

const TripsAgent        = lazy(() => lazyRetry(() => import('./components/agent/TripsAgent')))
const BookingAgent      = lazy(() => lazyRetry(() => import('./components/agent/BookingAgent')))
const ConfirmAgent      = lazy(() => lazyRetry(() => import('./components/agent/ConfirmAgent')))
const SuccessAgent      = lazy(() => lazyRetry(() => import('./components/agent/SuccessAgent')))

const Error404          = lazy(() => lazyRetry(() => import('./components/Error404')))

const Boats             = lazy(() => lazyRetry(() => import('./components/boats/Boats')))
const Boat              = lazy(() => lazyRetry(() => import('./components/boats/Boat')))

const Page              = lazy(() => lazyRetry(() => import('./components/pages/Page')))

const Merchandise       = lazy(() => lazyRetry(() => import('./components/merch/Merchandise')))
const MerchDetails      = lazy(() => lazyRetry(() => import('./components/merch/MerchDetails')))

const DiveSites         = lazy(() => lazyRetry(() => import('./components/diveSites/DiveSites')))
const DiveSite          = lazy(() => lazyRetry(() => import('./components/diveSites/DiveSite')))

const DayTripSchedule   = lazy(() => lazyRetry(() => import('./components/dayTrips/DayTripSchedule')))
const ItineraryDetails  = lazy(() => lazyRetry(() => import('./components/dayTrips/ItineraryDetails')))
const DayTripBooking    = lazy(() => lazyRetry(() => import('./components/dayTrips/DayTripBooking')))
const DayTripInformation= lazy(() => lazyRetry(() => import('./components/dayTrips/DayTripInformation')))
const DayTripPayment    = lazy(() => lazyRetry(() => import('./components/dayTrips/DayTripPayment')))
const DayTripSuccess    = lazy(() => lazyRetry(() => import('./components/dayTrips/DayTripSuccess')))

const ProductDetails    = lazy(() => lazyRetry(() => import('./components/products/ProductDetails')))
const Booking           = lazy(() => lazyRetry(() => import('./components/booking/Booking')))
const Services          = lazy(() => lazyRetry(() => import('./components/booking/Services')))
const Information       = lazy(() => lazyRetry(() => import('./components/booking/Information')))
const DeferredPayment   = lazy(() => lazyRetry(() => import('./components/booking/DeferredPayment')))
const FinalDetails      = lazy(() => lazyRetry(() => import('./components/booking/FinalDetails')))
const Payment           = lazy(() => lazyRetry(() => import('./components/booking/Payment')))
const Success           = lazy(() => lazyRetry(() => import('./components/booking/Success')))

const Reviews           = lazy(() => lazyRetry(() => import('./components/Reviews')))

const Courses           = lazy(() => lazyRetry(() => import('./components/courses/Courses')))
const Course            = lazy(() => lazyRetry(() => import('./components/courses/Course')))
const CourseBooking     = lazy(() => lazyRetry(() => import('./components/courses/CourseBooking')))
const CourseInformation = lazy(() => lazyRetry(() => import('./components/courses/CourseInformation')))
const CourseSuccess     = lazy(() => lazyRetry(() => import('./components/courses/CourseSuccess')))

const CoursesList       = lazy(() => lazyRetry(() => import('./components/admin/courses/CoursesList')))
const NewCourse         = lazy(() => lazyRetry(() => import('./components/admin/courses/NewCourse')))
const UpdateCourse      = lazy(() => lazyRetry(() => import('./components/admin/courses/UpdateCourse')))

const Login             = lazy(() => lazyRetry(() => import('./components/user/Login')))
const Register          = lazy(() => lazyRetry(() => import('./components/user/Register')))
const ForgotPassword    = lazy(() => lazyRetry(() => import('./components/user/ForgotPassword')))
const NewPassword       = lazy(() => lazyRetry(() => import('./components/user/NewPassword')))

const ListOrders        = lazy(() => lazyRetry(() => import('./components/order/ListOrders')))
const OrderDetails      = lazy(() => lazyRetry(() => import('./components/order/OrderDetails')))

const ListMerchOrders   = lazy(() => lazyRetry(() => import('./components/merchOrder/ListMerchOrders')))
const MerchOrderDetails = lazy(() => lazyRetry(() => import('./components/merchOrder/MerchOrderDetails')))

const Dashboard         = lazy(() => lazyRetry(() => import('./components/admin/Dashboard')))

const NewTripDetails    = lazy(() => lazyRetry(() => import('./components/admin/tripDetails/NewTripDetails')))
const UpdateTripDetails = lazy(() => lazyRetry(() => import('./components/admin/tripDetails/UpdateTripDetails')))

const ReviewList        = lazy(() => lazyRetry(() => import('./components/admin/reviews/ReviewList')))
const UpdateReview      = lazy(() => lazyRetry(() => import('./components/admin/reviews/UpdateReview')))

const UsersList         = lazy(() => lazyRetry(() => import('./components/admin/users/UsersList')))
const UpdateUser        = lazy(() => lazyRetry(() => import('./components/admin/users/UpdateUser')))

const ProductList       = lazy(() => lazyRetry(() => import('./components/admin/products/ProductList')))
const NewProduct        = lazy(() => lazyRetry(() => import('./components/admin/products/NewProduct')))
const UpdateProduct     = lazy(() => lazyRetry(() => import('./components/admin/products/UpdateProduct')))

const BoatList          = lazy(() => lazyRetry(() => import('./components/admin/boats/BoatList')))
const NewBoat           = lazy(() => lazyRetry(() => import('./components/admin/boats/NewBoat')))
const UpdateBoat        = lazy(() => lazyRetry(() => import('./components/admin/boats/UpdateBoat')))

const DiveSiteList      = lazy(() => lazyRetry(() => import('./components/admin/diveSites/DiveSiteList')))
const NewDiveSite       = lazy(() => lazyRetry(() => import('./components/admin/diveSites/NewDiveSite')))
const UpdateDiveSite    = lazy(() => lazyRetry(() => import('./components/admin/diveSites/UpdateDiveSite')))

const OrdersList        = lazy(() => lazyRetry(() => import('./components/admin/orders/OrdersList')))
const ProcessOrder      = lazy(() => lazyRetry(() => import('./components/admin/orders/ProcessOrder')))

const MerchOrdersList   = lazy(() => lazyRetry(() => import('./components/admin/merchOrders/MerchOrdersList')))
const MerchProcessOrder = lazy(() => lazyRetry(() => import('./components/admin/merchOrders/MerchProcessOrder')))

const MerchList         = lazy(() => lazyRetry(() => import('./components/admin/merch/MerchList')))
const NewMerch          = lazy(() => lazyRetry(() => import('./components/admin/merch/NewMerch')))
const UpdateMerch       = lazy(() => lazyRetry(() => import('./components/admin/merch/UpdateMerch')))

const PageList          = lazy(() => lazyRetry(() => import('./components/admin/pages/PageList')))
const NewPage           = lazy(() => lazyRetry(() => import('./components/admin/pages/NewPage')))
const UpdatePage        = lazy(() => lazyRetry(() => import('./components/admin/pages/UpdatePage')))

const NewHomepage       = lazy(() => lazyRetry(() => import('./components/admin/homepage/NewHomepage')))
const UpdateHomepage    = lazy(() => lazyRetry(() => import('./components/admin/homepage/UpdateHomepage')))

const stripePromise = process.env.REACT_APP_NODE_ENV === 'PRODUCTION'
  ? loadStripe(process.env.REACT_APP_STRIPE_API_KEY)
  : loadStripe(process.env.REACT_APP_STRIPE_API_KEY_DEV)

const App = () => {     

  const [ stripeApiKey, setStripeApiKey ] = useState('')
  
  useEffect(() => {    

    store.dispatch(loadUser())   

    async function getStripApiKey() {
      const { data } = await axios.get('/api/v1/stripeapi')
      setStripeApiKey(data.stripeApiKey)
    }

    getStripApiKey()
    
  }, [])   

  return (
    <>
    {stripeApiKey && 
      
       <Elements stripe={stripePromise}>
    
        <Header />

        <main>

          <ScrollToTop />      

          <Suspense fallback={<Loader hideProgress />}>

            <Routes>

              <Route path="/"                             element={<Home />} /> 
              <Route path="/trip-schedules"               element={<Trips />} />               
              <Route path="/trip-list"                    element={<TripsList />} />   
              <Route path="/trip-itinerary"               element={<TripItinerary />} />   
              <Route path="/trip-pickups"                 element={<Pickup />} />   
              <Route path="/equipment-rental"             element={<Equipment />} />   

              <Route path="/trip-list-agent"              element={<PrivateRoute isAgent={true}><TripsAgent /></PrivateRoute>} />   
              <Route path="/booking-agent"                element={<PrivateRoute isAgent={true}><BookingAgent /></PrivateRoute>} />   
              <Route path="/confirm-agent"                element={<PrivateRoute isAgent={true}><ConfirmAgent /></PrivateRoute>} />   
              <Route path="/success-agent/:id"            element={<PrivateRoute isAgent={true}><SuccessAgent /></PrivateRoute>} />   

              <Route path="/trip/:id"                     element={<ProductDetails />} />

              <Route path="/day-trip-schedule"            element={<DayTripSchedule />} /> 
              <Route path="/day-trip-itinerary"           element={<ItineraryDetails />} /> 
              <Route path="/day-trip-booking"             element={<DayTripBooking />} /> 
              <Route path="/day-trip-information"         element={<DayTripInformation />} />
              <Route path="/day-trip-payment"             element={<DayTripPayment />} />
              <Route path="/day-trip-success"             element={<DayTripSuccess />} />
              
              <Route path="/booking"                      element={<Booking />} />
              <Route path="/services"                     element={<Services />} />              

              <Route path="/boats"                        element={<Boats />} /> 
              <Route path="/boat/:slug"                   element={<Boat />} /> 

              <Route path="/page/:slug"                   element={<Page />} /> 

              <Route path="/merchandise"                  element={<Merchandise />} /> 
              <Route path="/merch/:slug"                  element={<MerchDetails />} /> 

              <Route path="/dive-sites/:destination"      element={<DiveSites />} /> 
              <Route path="/dive-site/:slug"              element={<DiveSite />} /> 

              <Route path="/courses"                      element={<Courses />} /> 
              <Route path="/course/:slug"                 element={<Course />} />  
              <Route path="/course-booking"               element={<CourseBooking />} /> 
              <Route path="/course-information"           element={<CourseInformation />} />
              <Route path="/course-success"               element={<CourseSuccess />} />

              <Route path="/similan-islands-liveaboard-reviews" element={<Reviews />} />              
          
              <Route path="/login"                        element={<Login />} /> 
              <Route path="/register"                     element={<Register />} />
              <Route path="/password/forgot"              element={<ForgotPassword />} />
              <Route path="/password/reset/:token"        element={<NewPassword />} /> 

              <Route path="/information"                  element={<PrivateRoute><Information /></PrivateRoute>} />
              <Route path="/deferred-payment/:id"         element={<PrivateRoute><DeferredPayment /></PrivateRoute>} />
              <Route path="/final-details/:id"            element={<PrivateRoute><FinalDetails /></PrivateRoute>} />
              <Route path="/payment"                      element={<PrivateRoute><Payment /></PrivateRoute>} />
              <Route path="/success/:id"                  element={<PrivateRoute><Success /></PrivateRoute>} />

              <Route path="/bookings/me"                  element={<PrivateRoute><ListOrders /></PrivateRoute>} />
              <Route path="/booking/:id"                  element={<PrivateRoute><OrderDetails /></PrivateRoute>} /> 

              <Route path="/orders/me"                    element={<PrivateRoute><ListMerchOrders /></PrivateRoute>} />
              <Route path="/order/:id"                    element={<PrivateRoute><MerchOrderDetails /></PrivateRoute>} /> 

              <Route path="/admin/dashboard"              element={<PrivateRoute isAdmin={true}><Dashboard /></PrivateRoute>} />
              
              <Route path="/admin/tripDetail"             element={<PrivateRoute isAdmin={true}><NewTripDetails /></PrivateRoute>} />
              <Route path="/admin/tripDetail/:id"         element={<PrivateRoute isAdmin={true}><UpdateTripDetails /></PrivateRoute>} />

              <Route path="/admin/users"                  element={<PrivateRoute isAdmin={true}><UsersList /></PrivateRoute>} />
              <Route path="/admin/user/:id"               element={<PrivateRoute isAdmin={true}><UpdateUser /></PrivateRoute>} />

              <Route path="/admin/products"               element={<PrivateRoute isAdmin={true}><ProductList /></PrivateRoute>} />                
              <Route path="/admin/product"                element={<PrivateRoute isAdmin={true}><NewProduct /></PrivateRoute>} />
              <Route path="/admin/product/:id"            element={<PrivateRoute isAdmin={true}><UpdateProduct /></PrivateRoute>} />  

              <Route path="/admin/boats"                  element={<PrivateRoute isAdmin={true}><BoatList /></PrivateRoute>} />                
              <Route path="/admin/boat"                   element={<PrivateRoute isAdmin={true}><NewBoat /></PrivateRoute>} />
              <Route path="/admin/boat/:id"               element={<PrivateRoute isAdmin={true}><UpdateBoat /></PrivateRoute>} />  

              <Route path="/admin/dive-sites"             element={<PrivateRoute isAdmin={true}><DiveSiteList /></PrivateRoute>} />                
              <Route path="/admin/dive-site"              element={<PrivateRoute isAdmin={true}><NewDiveSite /></PrivateRoute>} />
              <Route path="/admin/dive-site/:id"          element={<PrivateRoute isAdmin={true}><UpdateDiveSite /></PrivateRoute>} />                          
              
              <Route path="/admin/reviews"                element={<PrivateRoute isAdmin={true}><ReviewList /></PrivateRoute>} />
              <Route path="/admin/review/:id"             element={<PrivateRoute isAdmin={true}><UpdateReview /></PrivateRoute>} />
           
              <Route path="/admin/courses"                element={<PrivateRoute isAdmin={true}><CoursesList /></PrivateRoute>} />                
              <Route path="/admin/course"                 element={<PrivateRoute isAdmin={true}><NewCourse /></PrivateRoute>} />
              <Route path="/admin/course/:id"             element={<PrivateRoute isAdmin={true}><UpdateCourse /></PrivateRoute>} />
              
              <Route path="/admin/orders"                 element={<PrivateRoute isAdmin={true}><OrdersList /></PrivateRoute>} />
              <Route path="/admin/order/:id"              element={<PrivateRoute isAdmin={true}><ProcessOrder /></PrivateRoute>} />

              <Route path="/admin/merchandises"           element={<PrivateRoute isAdmin={true}><MerchList /></PrivateRoute>} />                
              <Route path="/admin/merchandise"            element={<PrivateRoute isAdmin={true}><NewMerch /></PrivateRoute>} />
              <Route path="/admin/merchandise/:id"        element={<PrivateRoute isAdmin={true}><UpdateMerch /></PrivateRoute>} />        
            
              <Route path="/admin/merchOrders"            element={<PrivateRoute isAdmin={true}><MerchOrdersList /></PrivateRoute>} />
              <Route path="/admin/merchOrder/:id"         element={<PrivateRoute isAdmin={true}><MerchProcessOrder /></PrivateRoute>} />

              <Route path="/admin/pages"                  element={<PrivateRoute isAdmin={true}><PageList /></PrivateRoute>} /> 
              <Route path="/admin/page"                   element={<PrivateRoute isAdmin={true}><NewPage /></PrivateRoute>} />               
              <Route path="/admin/page/:id"               element={<PrivateRoute isAdmin={true}><UpdatePage /></PrivateRoute>} />

              <Route path="/admin/home/new"               element={<PrivateRoute isAdmin={true}><NewHomepage /></PrivateRoute>} />               
              <Route path="/admin/home"                   element={<PrivateRoute isAdmin={true}><UpdateHomepage /></PrivateRoute>} />               

              <Route path="/terms-of-service"             element={<Navigate to="/page/terms-&-conditions" replace />} />
              <Route path="/privacy-policy"               element={<Navigate to="/page/privacy-policy" replace />} />
              <Route path="/company-policy"               element={<Navigate to="/page/booking-policy" replace />} />
              <Route path="/bookings.php"                 element={<Navigate to="/page/booking-policy" replace />} />
              <Route path="/diving-thailand-prices"       element={<Navigate to="/page/booking-policy" replace />} />
              <Route path="/payment"                      element={<Navigate to="/page/booking-policy" replace />} />
              <Route path="/frequently-asked-questions"   element={<Navigate to="/page/faq" replace />} />
              <Route path="/phuket-recompression-chamber" element={<Navigate to="/page/chamber" replace />} />
              <Route path="/westcoast-divers-insurance"   element={<Navigate to="/page/chamber" replace />} />
              <Route path="/phuket-thailand"              element={<Navigate to="/day-trip-schedule" replace />} />
              <Route path="/news-and-similan-islands-liveaboards-promotions" element={<Navigate to="/" replace />} />
              <Route path="/west-coast-divers-phuket"     element={<Navigate to="/" replace />} />
              <Route path="/similan-islands-liveaboards"  element={<Navigate to="/" replace />} />
              <Route path="/mv-pawara"                    element={<Navigate to="/boat/mv-pawara" replace />} />
              <Route path="/pawara-schedule"              element={<Navigate to="/trip-schedules?boatName=MV Pawara" replace />} />
              <Route path="/pawara-technical-data"        element={<Navigate to="/boat/mv-pawara" replace />} />
              <Route path="/pawara-charters"              element={<Navigate to="/boat/mv-pawara" replace />} />
              <Route path="/pawara-similan-dive-sites"    element={<Navigate to="/dive-sites/Similans-Richelieu" replace />} />
              <Route path="/pawara-equipment-rental"      element={<Navigate to="/trip-schedules?boatName=MV Pawara" replace />} />
              <Route path="/mv-sawasdee-fasai"            element={<Navigate to="/boat/mv-sawasdee-fasai" replace />} />
              <Route path="/sawasdee-fasai-schedule"      element={<Navigate to="/trip-schedules?boatName=MV Sawasdee Fasai" replace />} />
              <Route path="/fasai-technical-data"         element={<Navigate to="/boat/mv-sawasdee-fasai" replace />} />
              <Route path="/sawasdee-fasai-videos"        element={<Navigate to="/boat/mv-sawasdee-fasai" replace />} />
              <Route path="/sawasdee-fasai-charters"      element={<Navigate to="/boat/mv-sawasdee-fasai" replace />} />
              <Route path="/similan-dive-sites"           element={<Navigate to="/dive-sites/Similans-Richelieu" replace />} />
              <Route path="/fasai-equipment-rental"       element={<Navigate to="/trip-schedules?boatName=MV Sawasdee Fasai" replace />} />              
              <Route path="/padi-discover-scuba-diving"   element={<Navigate to="/course/padi-discover-scuba-diving" replace />} />
              <Route path="/padi-open-water"              element={<Navigate to="/course/padi-open-water-diver" replace />} />
              <Route path="/padi-advanced-open-water"     element={<Navigate to="/course/padi-advanced-open-water" replace />} />
              <Route path="/padi-rescue-diver"            element={<Navigate to="/course/padi-rescue-diver" replace />} />
              <Route path="/emergency-first-response"     element={<Navigate to="/course/emergency-first-response" replace />} />
              <Route path="/padi-nitrox-specialty"        element={<Navigate to="/course/padi-nitrox-specialty" replace />} />
              <Route path="/padi-specialty-courses"       element={<Navigate to="/course/padi-specialty-courses" replace />} />
              <Route path="/padi-divemaster"              element={<Navigate to="/course/padi-divemaster-course" replace />} />              
              <Route path="/dive-phuket-schedule"         element={<Navigate to="/day-trip-schedule" replace />} />
              <Route path="/phuket-dive-sites"            element={<Navigate to="/dive-sites/Phuket" replace />} />
              <Route path="/equipment-rental"             element={<Navigate to="/day-trip-schedule" replace />} />
              <Route path="/products"                     element={<Navigate to="/merchandise" replace />} />
              
              <Route path="*" element={<Error404 />} />
              
            </Routes>

          </Suspense>        

        </main>

        <Footer />

      </Elements>

    }
    </>
  )
}

export default App
