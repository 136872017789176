const ToolTip = ({ title, children, className, style }) => {

  return ( 

    <div className="tooltip-container">

      {children}

        <div 
          className={`tooltip text-center ${className ? className : null}`}
          style={style}
        >

          {title}

        </div>

    </div>

  )

}

export default ToolTip