const SettingsIcon = ({ style, aria }) => {

    return (     

        <svg style={style} aria-label={aria} className="icon" viewBox="0 0 1024 1024">
            <path d="M704 917.333333H362.666667V426.666667c0-82.474667 64.938667-155.84 147.413333-155.84h42.666667c82.474667 0 151.253333 73.344 151.253333 155.84v490.666666z" />
            <path d="M469.333333 234.666667h128v42.666666h-128z" />
            <path d="M576 256h-85.333333V85.333333a42.666667 42.666667 0 0 1 85.333333 0v170.666667z" />
            <path d="M533.333333 42.666667a42.666667 42.666667 0 1 0-0.021333 85.312A42.666667 42.666667 0 0 0 533.333333 42.666667z m0 64a21.333333 21.333333 0 1 1 0-42.666667 21.333333 21.333333 0 1 1 0 42.666667zM426.666667 149.333333h64v42.666667h-64zM576 149.333333h64v42.666667h-64z" />
            <path d="M552.746667 313.493333h-42.666667c-56.789333 0-104.746667 51.84-104.746667 113.173334v384h42.666667V426.666667c0-61.333333 47.957333-113.173333 104.746667-113.173334z" />
            <path d="M426.666667 192c0 11.797333-106.666667 21.333333-106.666667 21.333333a21.333333 21.333333 0 0 1-21.333333-21.333333V149.333333a21.333333 21.333333 0 0 1 21.333333-21.333333s106.666667 9.536 106.666667 21.333333v42.666667zM362.666667 810.666667h341.333333v149.333333H362.666667z" />
            <path d="M405.333333 810.688h42.666667v106.666667h-42.666667z" />
        </svg>
        
    )

}

export default SettingsIcon