import axios from 'axios'
import { 
    ADD_TO_CART, 
    REMOVE_ITEM_CART, 
    SAVE_BOOKING_INFO, 
    SAVE_DAYTRIP_INFO,
    SAVE_COURSE_INFO,
    REMOVE_ALL_CART,
    REMOVE_DAYTRIPS, 
    REMOVE_COURSES,
    SAVE_SHIPPING_INFO,
    ADD_MERCH_TO_CART,
    REMOVE_MERCH_ALL_CART,
    REMOVE_MERCH_ITEM_CART
} from "../constants/cartConstants"

export const addItemToCart = (item) => async (dispatch, getState) => {

    const { data } = await axios.get(`/api/v1/product/${item.id}`)

    const payload = {
        tripId: item.id,
        code: data.product.code,
        boatName: data.product.boatName,
        departure: data.product.departure,
        return: data.product.return,        
        destination: data.product.destination,
        guestArray: item.guestArray,
        discount: item.discount ? item.discount : 0,
        agentDiscount: item.agentDiscount ? item.agentDiscount : 0,
        deposit: item.deposit ? item.deposit : 0
    }

    dispatch({
        type: ADD_TO_CART,
        payload
    })

    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const removeItemFromCart = (id) => async (dispatch, getState) => {    

    dispatch({
        type: REMOVE_ITEM_CART,
        payload: id
    })

    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const emptyCart = () => async (dispatch) => {    

    dispatch({ type: REMOVE_ALL_CART })

    localStorage.removeItem('cartItems')
}

export const clearDayTrips = () => async (dispatch) => {    

    dispatch({ type: REMOVE_DAYTRIPS })

    localStorage.removeItem('daytripInfo')
}

export const clearCourses = () => async (dispatch) => {    

    dispatch({ type: REMOVE_COURSES })

    localStorage.removeItem('courseInfo')
}

export const saveBookingInfo = (data) => async (dispatch) => {    

    dispatch({
        type: SAVE_BOOKING_INFO,
        payload: data
    })

    localStorage.setItem('bookingInfo', JSON.stringify(data))
}

export const saveDaytripInfo = (data) => async (dispatch) => {    

    dispatch({
        type: SAVE_DAYTRIP_INFO,
        payload: data
    })

    localStorage.setItem('daytripInfo', JSON.stringify(data))
}

export const saveCourseInfo = (data) => async (dispatch) => {    

    dispatch({
        type: SAVE_COURSE_INFO,
        payload: data
    })

    localStorage.setItem('courseInfo', JSON.stringify(data))
}

export const addMerchItemToCart = (slug, quantity) => async (dispatch, getState) => {

    try {
        const { data } = await axios.get(`/api/v1/merch/${slug}`)

        dispatch({
            type: ADD_MERCH_TO_CART,
            payload: {
                merch: data.merch._id,
                slug: data.merch.slug,
                name: data.merch.name,
                price: data.merch.price,
                shipping: data.merch.shipping,
                shippingTwo: data.merch.shippingTwo,
                image: data.merch.images[0].thumb_url,
                stock: data.merch.stock,
                quantity,
            }
        })
        localStorage.setItem('merchCartItems', JSON.stringify(getState().merchCart.merchCartItems))
    } catch (error) {
        console.error("Error adding merch item to cart:", error)
    }
   
}

export const removeMerchItemFromCart = (id) => async (dispatch, getState) => {    

    dispatch({
        type: REMOVE_MERCH_ITEM_CART,
        payload: id
    })

    localStorage.setItem('merchCartItems', JSON.stringify(getState().merchCart.merchCartItems))
}

export const emptyMerchCart = () => async (dispatch, getState) => {    

    dispatch({ type: REMOVE_MERCH_ALL_CART })

    localStorage.removeItem('merchCartItems')
}

export const saveShippingInfo = (data) => async (dispatch) => {    

    dispatch({
        type: SAVE_SHIPPING_INFO,
        payload: data
    })

    localStorage.setItem('shippingInfo', JSON.stringify(data))
}