import { 
    ADMIN_TRIP_DETAILS_REQUEST,
    ADMIN_TRIP_DETAILS_SUCCESS,
    ADMIN_TRIP_DETAILS_FAIL,
    NEW_TRIP_DETAIL_REQUEST,
    NEW_TRIP_DETAIL_RESET,
    NEW_TRIP_DETAIL_SUCCESS,
    NEW_TRIP_DETAIL_FAIL,
    UPDATE_TRIP_DETAIL_REQUEST,
    UPDATE_TRIP_DETAIL_SUCCESS,
    UPDATE_TRIP_DETAIL_RESET,
    UPDATE_TRIP_DETAIL_FAIL,
    DELETE_TRIP_DETAIL_REQUEST,
    DELETE_TRIP_DETAIL_SUCCESS,
    DELETE_TRIP_DETAIL_RESET,
    DELETE_TRIP_DETAIL_FAIL,
    ALL_TRIP_DETAILS_REQUEST, 
    ALL_TRIP_DETAILS_SUCCESS, 
    ALL_TRIP_DETAILS_FAIL,  
    TRIP_DETAIL_DETAILS_REQUEST,
    TRIP_DETAIL_DETAILS_SUCCESS,
    TRIP_DETAIL_DETAILS_FAIL,
    ADMIN_TRIP_DETAIL_DETAILS_REQUEST,
    ADMIN_TRIP_DETAIL_DETAILS_SUCCESS,
    ADMIN_TRIP_DETAIL_DETAILS_FAIL,
    CLEAR_ERRORS 
} from '../constants/tripDetailsConstants'

export const tripDetailsReducer = ( state = { tripDetails: [] }, action ) => {
    switch(action.type) {

        case ALL_TRIP_DETAILS_REQUEST:
        case ADMIN_TRIP_DETAILS_REQUEST:
            return {
                loading: true,
                tripDetails: []
            }
        
        case ALL_TRIP_DETAILS_SUCCESS:
            return {
                loading: false,
                tripDetails: action.payload.tripDetails                
            }        
        
        case ADMIN_TRIP_DETAILS_SUCCESS:
            return {
                loading: false,
                tripDetails: action.payload.tripDetails
            }

        case ALL_TRIP_DETAILS_FAIL:
        case ADMIN_TRIP_DETAILS_FAIL:
             return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                 error: null
            }

        default:
            return state

    }
}

export const newTripDetailReducer = ( state = { tripDetail: {} }, action ) => {
    switch (action.type) {

        case NEW_TRIP_DETAIL_REQUEST:
            return {
                ...state,
                loading: true
            }
        case NEW_TRIP_DETAIL_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                tripDetail: action.payload.tripDetail
            }
        case NEW_TRIP_DETAIL_FAIL:
                return {
                    ...state,
                    error: action.payload,
                    loading: false,
                }
        case NEW_TRIP_DETAIL_RESET:
            return {
                ...state,
                success: false
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const tripDetailReducer = ( state = {}, action ) => {
    switch (action.type) {

        case DELETE_TRIP_DETAIL_REQUEST:
        case UPDATE_TRIP_DETAIL_REQUEST:
            return {
                ...state,
                loading: true
            }   
        case DELETE_TRIP_DETAIL_SUCCESS:        
            return {
                ...state,
                loading: false,
                isDeleted: action.payload
            }   
        case UPDATE_TRIP_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload
            }     
        
        case DELETE_TRIP_DETAIL_FAIL:       
        case UPDATE_TRIP_DETAIL_FAIL:
                return {
                    ...state,
                    error: action.payload
                }
        case DELETE_TRIP_DETAIL_RESET:
            return {
                ...state,
                isDeleted: false
            }
        case UPDATE_TRIP_DETAIL_RESET:
            return {
                ...state,
                isUpdated: false
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const tripDetailDetailsReducer = ( state = { tripDetail: {} }, action ) => {
    switch (action.type) {

        case TRIP_DETAIL_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case TRIP_DETAIL_DETAILS_SUCCESS:
            return {
                loading: false,
                tripDetail: action.payload
            }
        case TRIP_DETAIL_DETAILS_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const adminTripDetailDetailsReducer = ( state = { tripDetail: {} }, action ) => {
    switch (action.type) {

        case ADMIN_TRIP_DETAIL_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ADMIN_TRIP_DETAIL_DETAILS_SUCCESS:
            return {
                loading: false,
                tripDetail: action.payload
            }
        case ADMIN_TRIP_DETAIL_DETAILS_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}