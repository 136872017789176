export const ADMIN_TRIP_DETAILS_REQUEST = 'ADMIN_TRIP_DETAILS_REQUEST'
export const ADMIN_TRIP_DETAILS_SUCCESS = 'ADMIN_TRIP_DETAILS_SUCCESS'
export const ADMIN_TRIP_DETAILS_FAIL = 'ADMIN_TRIP_DETAILS_FAIL'

export const ALL_TRIP_DETAILS_REQUEST = 'ALL_TRIP_DETAILS_REQUEST'
export const ALL_TRIP_DETAILS_SUCCESS = 'ALL_TRIP_DETAILS_SUCCESS'
export const ALL_TRIP_DETAILS_FAIL = 'ALL_TRIP_DETAILS_FAIL'

export const NEW_TRIP_DETAIL_REQUEST = 'NEW_TRIP_DETAIL_REQUEST'
export const NEW_TRIP_DETAIL_SUCCESS = 'NEW_TRIP_DETAIL_SUCCESS'
export const NEW_TRIP_DETAIL_RESET = 'NEW_TRIP_DETAIL_RESET'
export const NEW_TRIP_DETAIL_FAIL = 'NEW_TRIP_DETAIL_FAIL'

export const DELETE_TRIP_DETAIL_REQUEST = 'DELETE_TRIP_DETAIL_REQUEST'
export const DELETE_TRIP_DETAIL_SUCCESS = 'DELETE_TRIP_DETAIL_SUCCESS'
export const DELETE_TRIP_DETAIL_RESET = 'DELETE_TRIP_DETAIL_RESET'
export const DELETE_TRIP_DETAIL_FAIL = 'DELETE_TRIP_DETAIL_FAIL'

export const UPDATE_TRIP_DETAIL_REQUEST = 'UPDATE_TRIP_DETAIL_REQUEST'
export const UPDATE_TRIP_DETAIL_SUCCESS = 'UPDATE_TRIP_DETAIL_SUCCESS'
export const UPDATE_TRIP_DETAIL_RESET = 'UPDATE_TRIP_DETAIL_RESET'
export const UPDATE_TRIP_DETAIL_FAIL = 'UPDATE_TRIP_DETAIL_FAIL'

export const TRIP_DETAIL_DETAILS_REQUEST = 'TRIP_DETAIL_DETAILS_REQUEST'
export const TRIP_DETAIL_DETAILS_SUCCESS = 'TRIP_DETAIL_DETAILS_SUCCESS'
export const TRIP_DETAIL_DETAILS_FAIL = 'TRIP_DETAIL_DETAILS_FAIL'

export const ADMIN_TRIP_DETAIL_DETAILS_REQUEST = 'ADMIN_TRIP_DETAIL_DETAILS_REQUEST'
export const ADMIN_TRIP_DETAIL_DETAILS_SUCCESS = 'ADMIN_TRIP_DETAIL_DETAILS_SUCCESS'
export const ADMIN_TRIP_DETAIL_DETAILS_FAIL = 'ADMIN_TRIP_DETAIL_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'
