export const ADMIN_MERCHS_REQUEST = 'ADMIN_MERCHS_REQUEST'
export const ADMIN_MERCHS_SUCCESS = 'ADMIN_MERCHS_SUCCESS'
export const ADMIN_MERCHS_FAIL = 'ADMIN_MERCHS_FAIL'

export const ALL_MERCHS_REQUEST = 'ALL_MERCHS_REQUEST'
export const ALL_MERCHS_SUCCESS = 'ALL_MERCHS_SUCCESS'
export const ALL_MERCHS_FAIL = 'ALL_MERCHS_FAIL'

export const NEW_MERCH_REQUEST = 'NEW_MERCH_REQUEST'
export const NEW_MERCH_SUCCESS = 'NEW_MERCH_SUCCESS'
export const NEW_MERCH_RESET = 'NEW_MERCH_RESET'
export const NEW_MERCH_FAIL = 'NEW_MERCH_FAIL'

export const DELETE_MERCH_REQUEST = 'DELETE_MERCH_REQUEST'
export const DELETE_MERCH_SUCCESS = 'DELETE_MERCH_SUCCESS'
export const DELETE_MERCH_RESET = 'DELETE_MERCH_RESET'
export const DELETE_MERCH_FAIL = 'DELETE_MERCH_FAIL'

export const UPDATE_MERCH_REQUEST = 'UPDATE_MERCH_REQUEST'
export const UPDATE_MERCH_SUCCESS = 'UPDATE_MERCH_SUCCESS'
export const UPDATE_MERCH_RESET = 'UPDATE_MERCH_RESET'
export const UPDATE_MERCH_FAIL = 'UPDATE_MERCH_FAIL'

export const MERCH_DETAILS_REQUEST = 'MERCH_DETAILS_REQUEST'
export const MERCH_DETAILS_SUCCESS = 'MERCH_DETAILS_SUCCESS'
export const MERCH_DETAILS_FAIL = 'MERCH_DETAILS_FAIL'

export const ADMIN_MERCH_DETAILS_REQUEST = 'ADMIN_MERCH_DETAILS_REQUEST'
export const ADMIN_MERCH_DETAILS_SUCCESS = 'ADMIN_MERCH_DETAILS_SUCCESS'
export const ADMIN_MERCH_DETAILS_FAIL = 'ADMIN_MERCH_DETAILS_FAIL'

export const DELETE_IMAGE_REQUEST = 'DELETE_IMAGE_REQUEST'
export const DELETE_IMAGE_SUCCESS = 'DELETE_IMAGE_SUCCESS'
export const DELETE_IMAGE_RESET = 'DELETE_IMAGE_RESET'
export const DELETE_IMAGE_FAIL = 'DELETE_IMAGE_FAIL'

export const UPDATE_IMAGE_REQUEST = 'UPDATE_IMAGE_REQUEST'
export const UPDATE_IMAGE_SUCCESS = 'UPDATE_IMAGE_SUCCESS'
export const UPDATE_IMAGE_RESET = 'UPDATE_IMAGE_RESET'
export const UPDATE_IMAGE_FAIL = 'UPDATE_IMAGE_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'
