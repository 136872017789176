import { 
    ADMIN_MERCHS_REQUEST,
    ADMIN_MERCHS_SUCCESS,
    ADMIN_MERCHS_FAIL,
    NEW_MERCH_REQUEST,
    NEW_MERCH_RESET,
    NEW_MERCH_SUCCESS,
    NEW_MERCH_FAIL,
    UPDATE_MERCH_REQUEST,
    UPDATE_MERCH_SUCCESS,
    UPDATE_MERCH_RESET,
    UPDATE_MERCH_FAIL,
    DELETE_MERCH_REQUEST,
    DELETE_MERCH_SUCCESS,
    DELETE_MERCH_RESET,
    DELETE_MERCH_FAIL,
    ALL_MERCHS_REQUEST, 
    ALL_MERCHS_SUCCESS, 
    ALL_MERCHS_FAIL,  
    MERCH_DETAILS_REQUEST,
    MERCH_DETAILS_SUCCESS,
    MERCH_DETAILS_FAIL,    
    ADMIN_MERCH_DETAILS_REQUEST,
    ADMIN_MERCH_DETAILS_SUCCESS,
    ADMIN_MERCH_DETAILS_FAIL,   
    DELETE_IMAGE_REQUEST,
    DELETE_IMAGE_SUCCESS,
    DELETE_IMAGE_RESET,
    DELETE_IMAGE_FAIL,
    UPDATE_IMAGE_REQUEST,
    UPDATE_IMAGE_SUCCESS,
    UPDATE_IMAGE_RESET,
    UPDATE_IMAGE_FAIL,
    CLEAR_ERRORS 
} from '../constants/merchConstants'

export const merchsReducer = ( state = { merchs: [] }, action ) => {
    switch(action.type) {

        case ALL_MERCHS_REQUEST:
        case ADMIN_MERCHS_REQUEST:
            return {
                loading: true,
                merchs: []
            }
        
        case ALL_MERCHS_SUCCESS:
            return {
                loading: false,
                merchs: action.payload.merchs,
                merchsCount: action.payload.merchsCount,
                resPerPage: action.payload.resPerPage,
                filteredMerchsCount: action.payload.filteredMerchsCount
            }        
        
        case ADMIN_MERCHS_SUCCESS:
            return {
                loading: false,
                merchsCount: action.payload.merchsCount,
                outOfStock: action.payload.outOfStock,
                merchs: action.payload.merchs
            }

        case ALL_MERCHS_FAIL:
        case ADMIN_MERCHS_FAIL:
             return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                 error: null
            }

        default:
            return state

    }
}

export const newMerchReducer = ( state = { merch: {} }, action ) => {
    switch (action.type) {

        case NEW_MERCH_REQUEST:
            return {
                ...state,
                loading: true
            }
        case NEW_MERCH_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                merch: action.payload.merch
            }
        case NEW_MERCH_FAIL:
                return {
                    ...state,
                    error: action.payload,
                    loading: false,
                }
        case NEW_MERCH_RESET:
            return {
                ...state,
                success: false
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const merchReducer = ( state = {}, action ) => {
    switch (action.type) {

        case DELETE_IMAGE_REQUEST:
        case UPDATE_IMAGE_REQUEST:
        case DELETE_MERCH_REQUEST:
        case UPDATE_MERCH_REQUEST:
            return {
                ...state,
                loading: true
            }   
        case DELETE_IMAGE_SUCCESS:     
        case DELETE_MERCH_SUCCESS:        
            return {
                ...state,
                loading: false,
                isDeleted: action.payload
            }   
        case UPDATE_MERCH_SUCCESS:
        case UPDATE_IMAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload
            }     
        
        case DELETE_MERCH_FAIL:
        case DELETE_IMAGE_FAIL:
        case UPDATE_IMAGE_FAIL:
        case UPDATE_MERCH_FAIL:
                return {
                    ...state,
                    error: action.payload
                }
        case DELETE_MERCH_RESET:
        case DELETE_IMAGE_RESET:
        case UPDATE_IMAGE_RESET:
            return {
                ...state,
                isDeleted: false
            }
        case UPDATE_MERCH_RESET:
            return {
                ...state,
                isUpdated: false
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const merchDetailsReducer = ( state = { merch: {} }, action ) => {
    switch (action.type) {

        case MERCH_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case MERCH_DETAILS_SUCCESS:
            return {
                loading: false,
                merch: action.payload
            }
        case MERCH_DETAILS_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const adminMerchDetailsReducer = ( state = { merch: {} }, action ) => {
    switch (action.type) {

        case ADMIN_MERCH_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ADMIN_MERCH_DETAILS_SUCCESS:
            return {
                loading: false,
                merch: action.payload
            }
        case ADMIN_MERCH_DETAILS_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}