export const ADMIN_BOATS_REQUEST = 'ADMIN_BOATS_REQUEST'
export const ADMIN_BOATS_SUCCESS = 'ADMIN_BOATS_SUCCESS'
export const ADMIN_BOATS_FAIL = 'ADMIN_BOATS_FAIL'

export const ALL_BOATS_REQUEST = 'ALL_BOATS_REQUEST'
export const ALL_BOATS_SUCCESS = 'ALL_BOATS_SUCCESS'
export const ALL_BOATS_FAIL = 'ALL_BOATS_FAIL'

export const NEW_BOAT_REQUEST = 'NEW_BOAT_REQUEST'
export const NEW_BOAT_SUCCESS = 'NEW_BOAT_SUCCESS'
export const NEW_BOAT_RESET = 'NEW_BOAT_RESET'
export const NEW_BOAT_FAIL = 'NEW_BOAT_FAIL'

export const DELETE_BOAT_REQUEST = 'DELETE_BOAT_REQUEST'
export const DELETE_BOAT_SUCCESS = 'DELETE_BOAT_SUCCESS'
export const DELETE_BOAT_RESET = 'DELETE_BOAT_RESET'
export const DELETE_BOAT_FAIL = 'DELETE_BOAT_FAIL'

export const UPDATE_BOAT_REQUEST = 'UPDATE_BOAT_REQUEST'
export const UPDATE_BOAT_SUCCESS = 'UPDATE_BOAT_SUCCESS'
export const UPDATE_BOAT_RESET = 'UPDATE_BOAT_RESET'
export const UPDATE_BOAT_FAIL = 'UPDATE_BOAT_FAIL'

export const BOAT_DETAILS_REQUEST = 'BOAT_DETAILS_REQUEST'
export const BOAT_DETAILS_SUCCESS = 'BOAT_DETAILS_SUCCESS'
export const BOAT_DETAILS_FAIL = 'BOAT_DETAILS_FAIL'

export const ADMIN_BOAT_DETAILS_REQUEST = 'ADMIN_BOAT_DETAILS_REQUEST'
export const ADMIN_BOAT_DETAILS_SUCCESS = 'ADMIN_BOAT_DETAILS_SUCCESS'
export const ADMIN_BOAT_DETAILS_FAIL = 'ADMIN_BOAT_DETAILS_FAIL'

export const DELETE_IMAGE_REQUEST = 'DELETE_IMAGE_REQUEST'
export const DELETE_IMAGE_SUCCESS = 'DELETE_IMAGE_SUCCESS'
export const DELETE_IMAGE_RESET = 'DELETE_IMAGE_RESET'
export const DELETE_IMAGE_FAIL = 'DELETE_IMAGE_FAIL'

export const UPDATE_IMAGE_REQUEST = 'UPDATE_IMAGE_REQUEST'
export const UPDATE_IMAGE_SUCCESS = 'UPDATE_IMAGE_SUCCESS'
export const UPDATE_IMAGE_RESET = 'UPDATE_IMAGE_RESET'
export const UPDATE_IMAGE_FAIL = 'UPDATE_IMAGE_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'