export const ADD_TO_CART = 'ADD_TO_CART'
export const REMOVE_ITEM_CART = 'REMOVE_ITEM_CART'
export const REMOVE_ALL_CART = 'REMOVE_ALL_CART'
export const REMOVE_DAYTRIPS = 'REMOVE_DAYTRIPS'
export const SAVE_BOOKING_INFO = 'SAVE_BOOKING_INFO'
export const SAVE_DAYTRIP_INFO = 'SAVE_DAYTRIP_INFO'
export const SAVE_COURSE_INFO = 'SAVE_COURSE_INFO'
export const REMOVE_COURSES = 'REMOVE_COURSES'
export const SAVE_SHIPPING_INFO = 'SAVE_SHIPPING_INFO'

export const ADD_MERCH_TO_CART = 'ADD_MERCH_TO_CART'
export const REMOVE_MERCH_ITEM_CART = 'REMOVE_MERCH_ITEM_CART'
export const REMOVE_MERCH_ALL_CART = 'REMOVE_MERCH_ALL_CART'




