export const RANDOM_REVIEWS_REQUEST = 'RANDOM_REVIEWS_REQUEST'
export const RANDOM_REVIEWS_SUCCESS = 'RANDOM_REVIEWS_SUCCESS'
export const RANDOM_REVIEWS_FAIL = 'RANDOM_REVIEWS_FAIL'

export const NEW_REVIEW_REQUEST = 'NEW_REVIEW_REQUEST'
export const NEW_REVIEW_SUCCESS = 'NEW_REVIEW_SUCCESS'
export const NEW_REVIEW_RESET = 'NEW_REVIEW_RESET'
export const NEW_REVIEW_FAIL = 'NEW_REVIEW_FAIL'

export const GET_REVIEWS_REQUEST = 'GET_REVIEWS_REQUEST'
export const GET_REVIEWS_SUCCESS = 'GET_REVIEWS_SUCCESS'
export const GET_REVIEWS_FAIL = 'GET_REVIEWS_FAIL'

export const GET_ALL_REVIEWS_REQUEST = 'GET_ALL_REVIEWS_REQUEST'
export const GET_ALL_REVIEWS_SUCCESS = 'GET_ALL_REVIEWS_SUCCESS'
export const GET_ALL_REVIEWS_FAIL = 'GET_ALL_REVIEWS_FAIL'

export const GET_ADMIN_REVIEWS_REQUEST = 'GET_ADMIN_REVIEWS_REQUEST'
export const GET_ADMIN_REVIEWS_SUCCESS = 'GET_ADMIN_REVIEWS_SUCCESS'
export const GET_ADMIN_REVIEWS_FAIL = 'GET_ADMIN_REVIEWS_FAIL'

export const GET_PENDING_REVIEWS_REQUEST = 'GET_PENDING_REVIEWS_REQUEST'
export const GET_PENDING_REVIEWS_SUCCESS = 'GET_PENDING_REVIEWS_SUCCESS'
export const GET_PENDING_REVIEWS_FAIL = 'GET_PENDING_REVIEWS_FAIL'

export const ADMIN_REVIEW_DETAILS_REQUEST = 'ADMIN_REVIEW_DETAILS_REQUEST'
export const ADMIN_REVIEW_DETAILS_SUCCESS = 'ADMIN_REVIEW_DETAILS_SUCCESS'
export const ADMIN_REVIEW_DETAILS_FAIL = 'ADMIN_REVIEW_DETAILS_FAIL'

export const UPDATE_ADMIN_REVIEW_REQUEST = 'UPDATE_ADMIN_REVIEW_REQUEST'
export const UPDATE_ADMIN_REVIEW_SUCCESS = 'UPDATE_ADMIN_REVIEW_SUCCESS'
export const UPDATE_ADMIN_REVIEW_RESET = 'UPDATE_ADMIN_REVIEW_RESET'
export const UPDATE_ADMIN_REVIEW_FAIL = 'UPDATE_ADMIN_REVIEW_FAIL'

export const DELETE_REVIEW_REQUEST = 'DELETE_REVIEW_REQUEST'
export const DELETE_REVIEW_SUCCESS = 'DELETE_REVIEW_SUCCESS'
export const DELETE_REVIEW_RESET = 'DELETE_REVIEW_RESET'
export const DELETE_REVIEW_FAIL = 'DELETE_REVIEW_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'