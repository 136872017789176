import axios from 'axios'
import { 
    ADMIN_BOATS_REQUEST,
    ADMIN_BOATS_SUCCESS,
    ADMIN_BOATS_FAIL,
    NEW_BOAT_REQUEST,
    NEW_BOAT_SUCCESS,
    NEW_BOAT_FAIL,
    UPDATE_BOAT_REQUEST,
    UPDATE_BOAT_SUCCESS,
    UPDATE_BOAT_FAIL,
    ALL_BOATS_REQUEST, 
    ALL_BOATS_SUCCESS, 
    ALL_BOATS_FAIL,        
    DELETE_BOAT_REQUEST,
    DELETE_BOAT_SUCCESS,
    DELETE_BOAT_FAIL,
    BOAT_DETAILS_REQUEST,
    BOAT_DETAILS_SUCCESS,
    BOAT_DETAILS_FAIL,
    ADMIN_BOAT_DETAILS_REQUEST,
    ADMIN_BOAT_DETAILS_SUCCESS,
    ADMIN_BOAT_DETAILS_FAIL,
    DELETE_IMAGE_REQUEST,
    DELETE_IMAGE_SUCCESS,
    DELETE_IMAGE_FAIL,
    UPDATE_IMAGE_REQUEST,
    UPDATE_IMAGE_SUCCESS,
    UPDATE_IMAGE_FAIL,
    CLEAR_ERRORS 
} from '../constants/boatConstants'

// Get boats
export const getBoats = ( currentPage = 1, category, price, rating = 0, filter = '', id = '' ) => async (dispatch) => {
    try {

        dispatch({ type: ALL_BOATS_REQUEST })               

        const { data } = await axios.get('/api/v1/boats')

        dispatch({
            type: ALL_BOATS_SUCCESS,
            payload: data
        })
        
    } catch (error) {

        dispatch({
            type: ALL_BOATS_FAIL,
            payload: error.response.data.message
        })

    }
}
// Get boats - (Admin)
export const getAdminBoats = () => async (dispatch) => {
    try {

        dispatch({ type: ADMIN_BOATS_REQUEST })

        const { data } = await axios.get('/api/v1/admin/boats')

        dispatch({
            type: ADMIN_BOATS_SUCCESS,
            payload: data
        })
        
    } catch (error) {

        dispatch({
            type: ADMIN_BOATS_FAIL,
            payload: error.response.data.message
        })

    }
}

// Get single boat details
export const getBoatDetails = (slug) => async (dispatch) => {
    
    try {

        dispatch({ type: BOAT_DETAILS_REQUEST })

        const { data } = await axios.get(`/api/v1/boat/${slug}`)

        dispatch({
            type: BOAT_DETAILS_SUCCESS,
            payload: data.boat
        })
        
    } catch (error) {

        dispatch({
            type: BOAT_DETAILS_FAIL,
            payload: error.response.data.message
        })

    }
}
// Get single boat details Admin
export const getAdminBoatDetails = (id) => async (dispatch) => {
    try {

        dispatch({ type: ADMIN_BOAT_DETAILS_REQUEST })        

        const { data } = await axios.get(`/api/v1/admin/boat/${id}`)

        dispatch({
            type: ADMIN_BOAT_DETAILS_SUCCESS,
            payload: data.boat
        })
        
    } catch (error) {

        dispatch({
            type: ADMIN_BOAT_DETAILS_FAIL,
            payload: error.response.data.message
        })

    }
}

// New boat (Admin)
export const newBoat = (boatData) => async (dispatch) => {
    try {

        dispatch({ type: NEW_BOAT_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'                
            }
        }

        const { data } = await axios.post('/api/v1/admin/boat/new', boatData, config)

        dispatch({
            type: NEW_BOAT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEW_BOAT_FAIL,
            payload: error.response.data.message
        })
    }
}
// Update boat (Admin)
export const updateBoat = (id, boatData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_BOAT_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'   
            }
        }
        const { data } = await axios.put(`/api/v1/admin/boat/${id}`, boatData, config)
        dispatch({
            type: UPDATE_BOAT_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: UPDATE_BOAT_FAIL,
            payload: error.response.data.message
        })
    }
}
// Delete boat (Admin)
export const deleteBoat = (id) => async (dispatch) => {
    try {

        dispatch({ type: DELETE_BOAT_REQUEST })      

        const { data } = await axios.delete(`/api/v1/admin/boat/${id}`)

        dispatch({
            type: DELETE_BOAT_SUCCESS,
            payload: data.success
        })
        
    } catch (error) {

        dispatch({
            type: DELETE_BOAT_FAIL,
            payload: error.response.data.message
        })

    }
}

// Delete image (Admin)
export const deleteImage = (id, imgIndex, imgId) => async (dispatch) => {
        
    try {

        dispatch({ type: DELETE_IMAGE_REQUEST })      

        const { data } = await axios.delete(`/api/v1/admin/boats/image?id=${id}&imgIndex=${imgIndex}&imgId=${imgId}`)

        dispatch({
            type: DELETE_IMAGE_SUCCESS,
            payload: data.success
        })
        
    } catch (error) {

        dispatch({
            type: DELETE_IMAGE_FAIL,
            payload: error.response.data.message
        })

    }
}
// Update images
export const updateImages = (id, initPos, finPos, imgId) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_IMAGE_REQUEST })      

        const { data } = await axios.put(`/api/v1/admin/boats/image?id=${id}&initPos=${initPos}&finPos=${finPos}&imgId=${imgId}`)

        dispatch({
            type: UPDATE_IMAGE_SUCCESS,
            payload: data.success
        })
        
    } catch (error) {

        dispatch({
            type: UPDATE_IMAGE_FAIL,
            payload: error.response.data.message
        })

    }
}

// Clear Errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}