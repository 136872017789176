import { 
    ADD_TO_CART, 
    REMOVE_ITEM_CART, 
    SAVE_BOOKING_INFO, 
    SAVE_DAYTRIP_INFO,
    SAVE_COURSE_INFO,
    REMOVE_ALL_CART,
    REMOVE_DAYTRIPS, 
    REMOVE_COURSES,
    SAVE_SHIPPING_INFO,
    ADD_MERCH_TO_CART,
    REMOVE_MERCH_ITEM_CART,
    REMOVE_MERCH_ALL_CART
} from "../constants/cartConstants"

export const cartReducer = (state = { cartItems: [], bookingInfo: {}, daytripInfo: {}, courseInfo: {} }, action) => {

    switch (action.type) {
        case ADD_TO_CART:           
            const item = action.payload
            const isItemExist = state.cartItems.find(i => i.product === item.product)

            if(isItemExist) {
                return {
                    ...state,
                    cartItems: state.cartItems.map(i => i.product === isItemExist.product ? item : i)
                }
            } else {
                return {
                    ...state,
                    cartItems: [...state.cartItems, item]
                }
            }
        case REMOVE_ITEM_CART:
            return {
                ...state,
                cartItems: state.cartItems.filter(i => i.product !== action.payload)
            }

        case REMOVE_ALL_CART:
            return {
                ...state,
                cartItems: []
            }
        case REMOVE_DAYTRIPS:
            return {
                ...state,
                daytripInfo: {}
            }
        case SAVE_BOOKING_INFO:
            return {
                ...state,
                bookingInfo: action.payload
            }   
        case SAVE_DAYTRIP_INFO:
            return {
                ...state,
                daytripInfo: action.payload
                }    
        case SAVE_COURSE_INFO:
            return {
                ...state,
                courseInfo: action.payload
                } 
        case REMOVE_COURSES:
            return {
                ...state,
                courseInfo: {}
            }      
        default:
            return state

    }
}

export const merchCartReducer = (state = { merchCartItems: [], shippingInfo: {} }, action) => {

    switch (action.type) {
        case ADD_MERCH_TO_CART:           
            const item = action.payload
            const isItemExist = state.merchCartItems.find(i => i.merch === item.merch)

            if(isItemExist) {
                return {
                    ...state,
                    merchCartItems: state.merchCartItems.map(i => i.merch === isItemExist.merch ? item : i)
                }
            } else {
                return {
                    ...state,
                    merchCartItems: [...state.merchCartItems, item]
                }
            }
        case REMOVE_MERCH_ITEM_CART:
            return {
                ...state,
                merchCartItems: state.merchCartItems.filter(i => i.merch !== action.payload)
            }
        case REMOVE_MERCH_ALL_CART:
            return {
                ...state,
                merchCartItems: []
            }
        case SAVE_SHIPPING_INFO:
            return {
                ...state,
                shippingInfo: action.payload
            }          
        default:
            return state

    }
}