import { 
    ADMIN_DIVE_SITES_REQUEST,
    ADMIN_DIVE_SITES_SUCCESS,
    ADMIN_DIVE_SITES_FAIL,
    NEW_DIVE_SITE_REQUEST,
    NEW_DIVE_SITE_RESET,
    NEW_DIVE_SITE_SUCCESS,
    NEW_DIVE_SITE_FAIL,
    UPDATE_DIVE_SITE_REQUEST,
    UPDATE_DIVE_SITE_SUCCESS,
    UPDATE_DIVE_SITE_RESET,
    UPDATE_DIVE_SITE_FAIL,
    DELETE_DIVE_SITE_REQUEST,
    DELETE_DIVE_SITE_SUCCESS,
    DELETE_DIVE_SITE_RESET,
    DELETE_DIVE_SITE_FAIL,
    ALL_DIVE_SITES_REQUEST, 
    ALL_DIVE_SITES_SUCCESS, 
    ALL_DIVE_SITES_FAIL,  
    DIVE_SITE_DETAILS_REQUEST,
    DIVE_SITE_DETAILS_SUCCESS,
    DIVE_SITE_DETAILS_FAIL,
    ADMIN_DIVE_SITE_DETAILS_REQUEST,
    ADMIN_DIVE_SITE_DETAILS_SUCCESS,
    ADMIN_DIVE_SITE_DETAILS_FAIL,
    DELETE_IMAGE_REQUEST,
    DELETE_IMAGE_SUCCESS,
    DELETE_IMAGE_RESET,
    DELETE_IMAGE_FAIL,
    UPDATE_IMAGE_REQUEST,
    UPDATE_IMAGE_SUCCESS,
    UPDATE_IMAGE_RESET,
    UPDATE_IMAGE_FAIL,
    CLEAR_ERRORS 
} from '../constants/diveSiteConstants'

export const diveSitesReducer = ( state = { diveSites: [] }, action ) => {
    switch(action.type) {

        case ALL_DIVE_SITES_REQUEST:
        case ADMIN_DIVE_SITES_REQUEST:
            return {
                loading: true,
                diveSites: []
            }
        
        case ALL_DIVE_SITES_SUCCESS:
            return {
                loading: false,
                diveSites: action.payload.diveSites                
            }        
        
        case ADMIN_DIVE_SITES_SUCCESS:
            return {
                loading: false,
                diveSites: action.payload.diveSites
            }

        case ALL_DIVE_SITES_FAIL:
        case ADMIN_DIVE_SITES_FAIL:
             return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                 error: null
            }

        default:
            return state

    }
}

export const newDiveSiteReducer = ( state = { diveSite: {} }, action ) => {
    switch (action.type) {

        case NEW_DIVE_SITE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case NEW_DIVE_SITE_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                diveSite: action.payload.diveSite
            }
        case NEW_DIVE_SITE_FAIL:
                return {
                    ...state,
                    error: action.payload,
                    loading: false,
                }
        case NEW_DIVE_SITE_RESET:
            return {
                ...state,
                success: false
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const diveSiteReducer = ( state = {}, action ) => {
    switch (action.type) {

        case DELETE_IMAGE_REQUEST:
        case UPDATE_IMAGE_REQUEST:
        case DELETE_DIVE_SITE_REQUEST:
        case UPDATE_DIVE_SITE_REQUEST:
            return {
                ...state,
                loading: true
            } 
        case DELETE_IMAGE_SUCCESS:     
        case DELETE_DIVE_SITE_SUCCESS:        
            return {
                ...state,
                loading: false,
                isDeleted: action.payload
            }   
        case UPDATE_DIVE_SITE_SUCCESS:
        case UPDATE_IMAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload
            }     
        case DELETE_IMAGE_FAIL:
        case UPDATE_IMAGE_FAIL:
        case DELETE_DIVE_SITE_FAIL:       
        case UPDATE_DIVE_SITE_FAIL:
                return {
                    ...state,
                    error: action.payload
                }
        case DELETE_IMAGE_RESET:
        case UPDATE_IMAGE_RESET:
        case DELETE_DIVE_SITE_RESET:
            return {
                ...state,
                isDeleted: false
            }
        case UPDATE_DIVE_SITE_RESET:
            return {
                ...state,
                isUpdated: false
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const diveSiteDetailsReducer = ( state = { diveSite: {} }, action ) => {
    switch (action.type) {

        case DIVE_SITE_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case DIVE_SITE_DETAILS_SUCCESS:
            return {
                loading: false,
                diveSite: action.payload
            }
        case DIVE_SITE_DETAILS_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const adminDiveSiteDetailsReducer = ( state = { diveSite: {} }, action ) => {
    switch (action.type) {

        case ADMIN_DIVE_SITE_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ADMIN_DIVE_SITE_DETAILS_SUCCESS:
            return {
                loading: false,
                diveSite: action.payload
            }
        case ADMIN_DIVE_SITE_DETAILS_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}