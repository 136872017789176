import { NavLink } from "react-router-dom"
import ArrowBackIcon from "../layout/icons/arrows/ArrowBackIcon"

const Courses = ({ setIsNavVisible, setIsCoursesVisible, courses }) => {

    const handleForms = () => {
        setIsNavVisible(true)
        setIsCoursesVisible(false)
    }

    return (
        <>
        <button
            onClick={handleForms}
            className="icon-button"
            style={{ margin: 5 }}
        >
            <ArrowBackIcon />
        </button>        

        <div className="wrapper">            

            <h3>PADI Courses</h3>

            <ul>
                {courses && courses.map((course, index) => (

                    <li 
                        key={index} 
                        onClick={() => setIsCoursesVisible(false)}
                    >
                        <NavLink 
                            className="nav-link"
                            to={`course/${course.slug}`}
                            style={{ display: 'block', padding: '24px 0' }}
                        >

                            {course.name}

                        </NavLink>
                    </li>

                ))}              

            </ul>     
            
        </div>
        </>
    )

}

export default Courses