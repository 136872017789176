import { useState } from 'react'
import { useSelector } from 'react-redux'
import ReCAPTCHA from 'react-google-recaptcha'
import Input from '../layout/forms/Input'
import axios from 'axios'
import SendIcon from '../layout/icons/navigation/SendIcon'
import { validateEmail } from '../../utils'
import CountrySelect from '../layout/forms/CountrySelect'

function Contact() {

    const { user } = useSelector( state => state.auth )  

    const [ firstName,   setFirstName   ] = useState((user && user.firstName)  || '')
    const [ lastName,    setLastName    ] = useState((user && user.lastName)  || '')
    const [ email,       setEmail       ] = useState((user && user.email) || '')
    const [ country,     setCountry     ] = useState('')    
    const [ phoneNo,     setPhoneNo     ] = useState('')
    const [ countryCode, setCountryCode ] = useState('') 
    const [ message,     setMessage     ] = useState('')
    const [ captcha,     setCaptcha     ] = useState(false)
    const [ success,     setSuccess     ] = useState(false)   
    const [ image ] = useState(user 
        ? user.avatar 
            ? user.avatar.url 
            : 'https://res.cloudinary.com/marine-graphics/image/upload/v1687028506/fishslates/logo/default-avatar_iuxxjq.jpg'
        : 'https://res.cloudinary.com/marine-graphics/image/upload/v1687028506/fishslates/logo/default-avatar_iuxxjq.jpg'
    )   

    const submitHandler = (e) => {
        e.preventDefault()         
        setCaptcha(true)  
    }

    const onChange = (value) => {      
        
        const formData = new FormData()
        formData.set('fName', firstName)
        formData.set('lName', lastName)
        formData.set('email', email)
        formData.set('country', country)
        formData.set('countryCode', countryCode)
        formData.set('phoneNo', phoneNo)
        formData.set('message', message)   
        formData.set('image', image)
        formData.set('key', value)   
        
        axios.post( '/api/v1/contact', formData )
            .then(res => {
                setSuccess(true)
                setCaptcha(false)
            }).catch((res) => {
                setCaptcha(false)
                console.log(res)
            })                        
    }
    
    return (  
        <>
        {!captcha ? (

            !success ? (
                <>  
                <form onSubmit={submitHandler} className="wrapper">   

                    <h3>Contact Us</h3>   

                    <div className="mobile-parent" style={{ gap: 2, marginBottom: 20 }}>     

                        <Input
                            placeholder="First name *"
                            value={firstName} 
                            onChange={(e) => setFirstName(e.target.value)} 
                            filled={firstName}
                            required
                            autoFocus
                        /> 

                        <Input
                            placeholder="Last name *"
                            value={lastName} 
                            onChange={(e) => setLastName(e.target.value)} 
                            filled={lastName}
                            required
                        />

                    </div>   

                    <div className="d-flex direction-column gap-2">

                        <Input
                            placeholder="Email *"
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            filled={validateEmail(email)}
                            required
                        />

                        <Input
                            placeholder="Phone/ WhatsApp *"
                            value={phoneNo} 
                            onChange={(e) => setPhoneNo(e.target.value)} 
                            filled={phoneNo}
                            required
                        /> 

                        <CountrySelect 
                            country={country}
                            setCountry={setCountry}
                            countryCode={countryCode}
                            setCountryCode={setCountryCode}
                            filled={country}
                            required
                        />                  
                    
                        <Input 
                            type="textarea"
                            placeholder="Message *"
                            value={message} 
                            onChange={(e) => setMessage(e.target.value)}                         
                            rows="10"
                            filled={message}
                            required
                        />

                    </div>

                    <button 
                        type="submit"                             
                        className="button booking-button"
                        disabled={ !firstName || !lastName || !validateEmail(email) || !country || !phoneNo || !message }
                        style={{ margin:' 40px auto 0 auto', width: 'auto' }}
                    >
                        <SendIcon style={{ color: 'white' }} />
                        &nbsp;
                        Send Email
                    </button>
                        
                </form>  
                </>
            ) : (
                <div className="wrapper">
                    <h3>Mail sent!</h3>

                    <div className="text-center" style={{ marginBottom: 80 }}>

                        <SendIcon style={{ fontSize: '5rem', color: 'var(--cta-green)' }} />

                    </div>                        

                    <p>
                        Thank you for your email. We will get back to you soon. 
                        <br />
                        <br />
                        Check your 'junk' mail if you don't hear from us. 
                        <br />
                        <br />
                        You may now close this tab
                    </p>
                </div>
            )  
        ) : (            
            <div className="wrapper">
                <h3>Security Check</h3>
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={onChange}
                />
            </div>             
        )}
        </>  
    )

}

export default Contact
