export const AirportShuttleIcon = ({ style, aria }) => {

    return (

        <svg className="icon" viewBox="0 0 24 24" style={style} aria-label={aria}>
            <path d="M17 5H3c-1.1 0-2 .89-2 2v9h2c0 1.65 1.34 3 3 3s3-1.35 3-3h5.5c0 1.65 1.34 3 3 3s3-1.35 3-3H23v-5zM3 11V7h4v4zm3 6.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5m7-6.5H9V7h4zm4.5 6.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5M15 11V7h1l4 4z" />
        </svg>

    )

}
