import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../actions/userActions'
import { useAlert } from 'react-alert'
import { formatMyDate } from '../../utils'
import Loader from '../layout/tools/Loader'
import EditOutlinedIcon from '../layout/icons/actions/EditOutlinedIcon'
import LogoutIcon from '../layout/icons/login/LogoutIcon'
import DirectionsBoatIcon from '../layout/icons/navigation/DirectionsBoatIcon'
import ShoppingCartIcon from '../layout/icons/navigation/ShoppingCartIcon'
import AccessTimeIcon from '../layout/icons/navigation/AccessTimeIcon'
import PassportIcon from '../layout/icons/travel/PassportIcon'
import SendIcon from '../layout/icons/navigation/SendIcon'
import { PhoneIcon } from '../layout/icons/navigation/PhoneIcon'

const Profile = ({ setIsProfileVisible, setIsUpdatePasswordVisible, setIsUpdateProfileVisible }) => {

    const alert    = useAlert()
    const dispatch = useDispatch()

    const { user, loading } = useSelector( state => state.auth )   

    const logoutHandler = () => {
        dispatch(logout())
        alert.success('Logged out')
        setIsProfileVisible(false)
    }

    const handleUpdateProfile = () => {
        setIsProfileVisible(false)
        setIsUpdateProfileVisible(true)
    }

    const handleUpdatePassword = () => {
        setIsProfileVisible(false)
        setIsUpdatePasswordVisible(true)
    }
   
    return (
        <>
        {loading ? <Loader /> : (              

            <div className="wrapper">

                <h3>Profile</h3>    

                <div className="parent gap-4">

                    <figure 
                        className="d-flex align-items-center justify-content-center"
                        style={{ width: '159px', height: '159px' }}
                    >
                        <img 
                            src={user.avatar?.url || '/images/default-avatar.jpg'} 
                            alt={user.name} 
                            className="object-fit round"
                        />
                    </figure>

                    <div 
                        className="d-flex justify-content-between direction-column"
                        style={{ flex: 1, textAlign: 'right'}}
                    >                         
                        <small className="d-flex justify-content-end align-items-center gap-1">
                            <b>Name</b>
                            <PassportIcon style={{ fontSize: '1rem' }} />
                        </small>   
                        <p>{user.title} {user.firstName} {user.lastName} <small>({user.role})</small></p>
                        
                        <small className="d-flex justify-content-end align-items-center gap-1">
                            <b>Email</b>
                            <SendIcon style={{ fontSize: '1rem' }} />
                        </small>
                        <p>{user.email}</p>
                        <small className="d-flex justify-content-end align-items-center gap-1">
                            <b>Phone</b>
                            <PhoneIcon style={{ fontSize: '1rem' }} />
                        </small>
                        <p>{user.phone}</p>
                    </div>
                    
                </div>

                <small className="d-flex align-items-center gap-1">
                    <b>Joined</b>
                    <AccessTimeIcon style={{ fontSize: '1rem' }} />
                    {formatMyDate(user.createdAt)}
                </small>  

                <div className="parent gap-2" style={{ marginTop: 40, alignItems: 'flex-start' }}>
                    
                    <div>                          

                        <Link to="/bookings/me" onClick={() => setIsProfileVisible(false)}>
                            <small className="d-flex align-items-center">
                                <button className="icon-button">
                                    <DirectionsBoatIcon />
                                </button>
                                &nbsp; My Bookings
                            </small>
                            
                        </Link>   

                        <br />

                        <Link to="/orders/me" onClick={() => setIsProfileVisible(false)}>
                            <small className="d-flex align-items-center">
                                <button className="icon-button">
                                    <ShoppingCartIcon />
                                </button>
                                &nbsp; My Orders
                            </small>
                            
                        </Link>   

                        <br />

                        <small className="d-flex align-items-center">                            
                            <button className="icon-button" onClick={logoutHandler}>
                                <LogoutIcon />
                            </button>
                            &nbsp; Logout  
                        </small>      

                    </div>                                 

                    <div>   
                        <small className="d-flex align-items-center justify-content-end">
                            Update Profile  &nbsp; 
                            <button className="icon-button" onClick={handleUpdateProfile}>
                                <EditOutlinedIcon />
                            </button>
                        </small>
                        <br />                        
                        <small className="d-flex align-items-center justify-content-end">
                            Update Password  &nbsp; 
                            <button className="icon-button" onClick={handleUpdatePassword}>
                                <EditOutlinedIcon />
                            </button>
                        </small>   
                    </div> 

                </div>    

            </div>                    
               
        )}            
        </>
    )

}

export default Profile
