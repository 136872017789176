import { NavLink } from "react-router-dom"
import ArrowBackIcon from "../layout/icons/arrows/ArrowBackIcon"

const links = [
    {
        name: 'Similans - Richelieu',
        slug: 'Similans-Richelieu',
    },
    {
        name: 'Phuket',
        slug: 'Phuket',
    },    
]

const Destinations = ({ setIsNavVisible, setIsDestinationsVisible }) => {

    const handleForms = () => {
        setIsNavVisible(true)
        setIsDestinationsVisible(false)
    }  

    return (
        <>
        <button
            onClick={handleForms}
            className="icon-button"
            style={{ margin: 5 }}
        >
            <ArrowBackIcon />
        </button>        

        <div className="wrapper">            

            <h3>Destinations</h3>

            <ul>
                {links && links.map((link, index) => (             

                    <li 
                        key={index} 
                        onClick={() => setIsDestinationsVisible(false)}
                        style={{ borderBottom: 'solid 1px var(--border-color)' }}
                    >
                        <NavLink 
                            className="nav-link"
                            to={`dive-sites/${link.slug}`}
                            style={{ display: 'block', padding: '24px 0' }}
                        >

                            {link.name}

                        </NavLink>
                    </li>

                ))}              

            </ul>     
            
        </div>
        </>
    )

}

export default Destinations