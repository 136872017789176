import { 
    ADMIN_BOATS_REQUEST,
    ADMIN_BOATS_SUCCESS,
    ADMIN_BOATS_FAIL,
    NEW_BOAT_REQUEST,
    NEW_BOAT_RESET,
    NEW_BOAT_SUCCESS,
    NEW_BOAT_FAIL,
    UPDATE_BOAT_REQUEST,
    UPDATE_BOAT_SUCCESS,
    UPDATE_BOAT_RESET,
    UPDATE_BOAT_FAIL,
    DELETE_BOAT_REQUEST,
    DELETE_BOAT_SUCCESS,
    DELETE_BOAT_RESET,
    DELETE_BOAT_FAIL,
    ALL_BOATS_REQUEST, 
    ALL_BOATS_SUCCESS, 
    ALL_BOATS_FAIL,  
    BOAT_DETAILS_REQUEST,
    BOAT_DETAILS_SUCCESS,
    BOAT_DETAILS_FAIL,
    ADMIN_BOAT_DETAILS_REQUEST,
    ADMIN_BOAT_DETAILS_SUCCESS,
    ADMIN_BOAT_DETAILS_FAIL,
    DELETE_IMAGE_REQUEST,
    DELETE_IMAGE_SUCCESS,
    DELETE_IMAGE_RESET,
    DELETE_IMAGE_FAIL,
    UPDATE_IMAGE_REQUEST,
    UPDATE_IMAGE_SUCCESS,
    UPDATE_IMAGE_RESET,
    UPDATE_IMAGE_FAIL,
    CLEAR_ERRORS 
} from '../constants/boatConstants'

export const boatsReducer = ( state = { boats: [] }, action ) => {
    switch(action.type) {

        case ALL_BOATS_REQUEST:
        case ADMIN_BOATS_REQUEST:
            return {
                loading: true,
                boats: []
            }
        
        case ALL_BOATS_SUCCESS:
            return {
                loading: false,
                boats: action.payload.boats                
            }        
        
        case ADMIN_BOATS_SUCCESS:
            return {
                loading: false,
                boats: action.payload.boats
            }

        case ALL_BOATS_FAIL:
        case ADMIN_BOATS_FAIL:
             return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                 error: null
            }

        default:
            return state

    }
}

export const newBoatReducer = ( state = { boat: {} }, action ) => {
    switch (action.type) {

        case NEW_BOAT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case NEW_BOAT_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                boat: action.payload.boat
            }
        case NEW_BOAT_FAIL:
                return {
                    ...state,
                    error: action.payload,
                    loading: false,
                }
        case NEW_BOAT_RESET:
            return {
                ...state,
                success: false
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const boatReducer = ( state = {}, action ) => {
    switch (action.type) {

        case DELETE_IMAGE_REQUEST:
        case UPDATE_IMAGE_REQUEST:
        case DELETE_BOAT_REQUEST:
        case UPDATE_BOAT_REQUEST:
            return {
                ...state,
                loading: true
            } 
        case DELETE_IMAGE_SUCCESS:     
        case DELETE_BOAT_SUCCESS:        
            return {
                ...state,
                loading: false,
                isDeleted: action.payload
            }   
        case UPDATE_BOAT_SUCCESS:
        case UPDATE_IMAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload
            }     
        case DELETE_IMAGE_FAIL:
        case UPDATE_IMAGE_FAIL:
        case DELETE_BOAT_FAIL:       
        case UPDATE_BOAT_FAIL:
                return {
                    ...state,
                    error: action.payload
                }
        case DELETE_IMAGE_RESET:
        case UPDATE_IMAGE_RESET:
        case DELETE_BOAT_RESET:
            return {
                ...state,
                isDeleted: false
            }
        case UPDATE_BOAT_RESET:
            return {
                ...state,
                isUpdated: false
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const boatDetailsReducer = ( state = { boat: {} }, action ) => {
    switch (action.type) {

        case BOAT_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case BOAT_DETAILS_SUCCESS:
            return {
                loading: false,
                boat: action.payload
            }
        case BOAT_DETAILS_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const adminBoatDetailsReducer = ( state = { boat: {} }, action ) => {
    switch (action.type) {

        case ADMIN_BOAT_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ADMIN_BOAT_DETAILS_SUCCESS:
            return {
                loading: false,
                boat: action.payload
            }
        case ADMIN_BOAT_DETAILS_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}