import { 
    NEW_REVIEW_REQUEST,
    NEW_REVIEW_SUCCESS,
    NEW_REVIEW_RESET,
    NEW_REVIEW_FAIL,
    RANDOM_REVIEWS_REQUEST,
    RANDOM_REVIEWS_SUCCESS,
    RANDOM_REVIEWS_FAIL,
    GET_ALL_REVIEWS_REQUEST,
    GET_ALL_REVIEWS_SUCCESS,
    GET_ALL_REVIEWS_FAIL,
    GET_ADMIN_REVIEWS_REQUEST,
    GET_ADMIN_REVIEWS_SUCCESS,
    GET_ADMIN_REVIEWS_FAIL,
    GET_PENDING_REVIEWS_REQUEST,
    GET_PENDING_REVIEWS_SUCCESS,
    GET_PENDING_REVIEWS_FAIL,
    ADMIN_REVIEW_DETAILS_REQUEST,
    ADMIN_REVIEW_DETAILS_SUCCESS,
    ADMIN_REVIEW_DETAILS_FAIL,
    UPDATE_ADMIN_REVIEW_REQUEST,
    UPDATE_ADMIN_REVIEW_SUCCESS,
    UPDATE_ADMIN_REVIEW_RESET,
    UPDATE_ADMIN_REVIEW_FAIL,
    DELETE_REVIEW_REQUEST,
    DELETE_REVIEW_SUCCESS,
    DELETE_REVIEW_RESET,
    DELETE_REVIEW_FAIL,
    CLEAR_ERRORS 
} from '../constants/reviewConstants'

export const newReviewReducer = ( state = {}, action ) => {
    switch (action.type) {

        case NEW_REVIEW_REQUEST:
            return {
                ...state,
                loading: true
            }

        case NEW_REVIEW_SUCCESS:
            return {
                loading: false,
                success: action.payload
            } 

        case NEW_REVIEW_RESET:
            return {
                ...state,
                success: false
            }

        case NEW_REVIEW_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

export const randomReviewsReducer = ( state = { reviews: [] }, action ) => {
    switch(action.type) {

        case RANDOM_REVIEWS_REQUEST:
            return {
                loading: true,
                reviews: []
            }  

        case RANDOM_REVIEWS_SUCCESS:
            return {
                loading: false,
                reviews: action.payload                 
            }   
       
        case RANDOM_REVIEWS_FAIL:
             return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                 error: null
            }

        default:
            return state

    }
}

export const allReviewsReducer = ( state = { reviews: [] }, action ) => {
    switch (action.type) {

        case GET_ALL_REVIEWS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case GET_ALL_REVIEWS_SUCCESS:
            return {
                loading: false,
                reviews: action.payload.reviews,
                resPerPage: action.payload.resPerPage,
                reviewCount: action.payload.reviewCount,
                average: action.payload.average
            }        
        
        case GET_ALL_REVIEWS_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

export const allAdminReviewsReducer = ( state = { reviews: [] }, action ) => {
    switch (action.type) {

        case GET_ADMIN_REVIEWS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case GET_ADMIN_REVIEWS_SUCCESS:
            return {
                loading: false,
                reviews: action.payload.reviews                
            }        
        
        case GET_ADMIN_REVIEWS_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

export const allPendingReviewsReducer = ( state = { reviews: [] }, action ) => {
    switch (action.type) {

        case GET_PENDING_REVIEWS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case GET_PENDING_REVIEWS_SUCCESS:
            return {
                loading: false,
                reviews: action.payload.reviews                
            }        
        
        case GET_PENDING_REVIEWS_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

export const adminReviewDetailsReducer = ( state = { review: {} }, action ) => {
    switch (action.type) {

        case ADMIN_REVIEW_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ADMIN_REVIEW_DETAILS_SUCCESS:
            return {
                loading: false,
                review: action.payload
            }
        case ADMIN_REVIEW_DETAILS_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const reviewReducer = ( state = {}, action ) => {
    switch (action.type) {

        case DELETE_REVIEW_REQUEST:
        case UPDATE_ADMIN_REVIEW_REQUEST:
            return {
                ...state,
                loading: true
            }
        case DELETE_REVIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload
            } 
        case UPDATE_ADMIN_REVIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload
            }         
        case UPDATE_ADMIN_REVIEW_FAIL:
        case DELETE_REVIEW_FAIL:
                return {
                    ...state,
                    error: action.payload,
                    loading: false,
                }
        case DELETE_REVIEW_RESET:
            return {
                ...state,
                isDeleted: false,
            }
        case UPDATE_ADMIN_REVIEW_RESET:
            return {
                ...state,
                isUpdated: false
            }
       
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

