import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useAlert } from 'react-alert'
import { logout } from '../../actions/userActions'
import DirectionsBoatIcon from '../layout/icons/navigation/DirectionsBoatIcon'
import LogoutIcon from '../layout/icons/login/LogoutIcon'
import SpeedIcon from '../layout/icons/navigation/SpeedIcon'
import PersonOutlineIcon from '../layout/icons/people/PersonOutlineIcon'
import ShoppingCartIcon from '../layout/icons/navigation/ShoppingCartIcon'

const DashboardNav = ({ setMenuVisible, setIsProfileVisible }) => {

    const alert    = useAlert()    
    const dispatch = useDispatch()

    const { user } = useSelector( state => state.auth  ) 

    const handleProfile = () => {
        setMenuVisible(false)
        setIsProfileVisible(isProfileVisible => !isProfileVisible)
    }

    const toggleMenu = () => {
        setMenuVisible(isMenuVisible => !isMenuVisible)
    }

    const logoutHandler = () => {        
        dispatch(logout())
        alert.success('Logged out')
        setMenuVisible(false)
    } 

    return (

        <div className="wrapper">

            <h3>User Menu</h3>

            <ul>
            
                {user && user.role === 'admin' && (
                    <li>
                        <Link 
                            to="/admin/dashboard" 
                            onClick={toggleMenu}
                            className="nav-link"
                        >
                            Dashboard &nbsp;
                            <button className="icon-button" aria-label="Dashboard">
                                <SpeedIcon />
                            </button>
                        </Link>
                    </li>
                )}  

                <li>
                    <Link 
                        to="/bookings/me" 
                        onClick={toggleMenu}
                        className="nav-link"
                    >
                        Bookings &nbsp; 
                        <button className="icon-button" aria-label="Orders">
                            <DirectionsBoatIcon />
                        </button>
                    </Link>
                </li>

                <li>
                    <Link 
                        to="/orders/me" 
                        onClick={toggleMenu}
                        className="nav-link"
                    >
                        Orders &nbsp; 
                        <button className="icon-button" aria-label="Orders">
                            <ShoppingCartIcon />
                        </button>
                    </Link>
                </li>

                <li>
                    <span className="nav-link cursor-pointer" onClick={handleProfile}>
                        Profile &nbsp; 
                        <button className="icon-button" aria-label="Profile">
                            <PersonOutlineIcon />
                        </button>
                    </span>
                </li>
                
                <li>
                    <span className="nav-link cursor-pointer" onClick={logoutHandler}>
                        Logout &nbsp; 
                        <button className="icon-button" aria-label="Logout">
                            <LogoutIcon />
                        </button>
                    </span>
                </li>

            </ul>

        </div>

    )

}

export default DashboardNav