import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { saveShippingInfo } from '../../actions/cartActions'
import Input from '../layout/forms/Input'
import ArrowBackIcon from '../layout/icons/arrows/ArrowBackIcon'
import CheckoutSteps from '../merch/checkoutSteps/CheckoutSteps'
import CountrySelect from '../layout/forms/CountrySelect'
import GlobeIcon from '../layout/icons/navigation/GlobeIcon'
import SendIcon from '../layout/icons/navigation/SendIcon'

const Details = ({ setIsCartOpen, setIsDetailsOpen, setIsConfirmOpen }) => {
  
    const handleBack = () => {
        setIsDetailsOpen(false)  
        setIsCartOpen(true)
    }

    const dispatch = useDispatch()

    const { shippingInfo } = useSelector( state => state.merchCart )   
    const { user         } = useSelector( state => state.auth )

    const [ name,        setName        ] = useState( shippingInfo.name        || (user && user.firstName + ' ' + user.lastName)  || '' )
    const [ email,       setEmail       ] = useState( shippingInfo.email       || (user && user.email) || '' )
    const [ address,     setAddress     ] = useState( shippingInfo.address     || '' )
    const [ city,        setCity        ] = useState( shippingInfo.city        || '' )
    const [ subDistrict, setSubDistrict ] = useState( shippingInfo.subDistrict || '' )
    const [ district,    setDistrict    ] = useState( shippingInfo.district    || '' )
    const [ postalCode,  setPostalCode  ] = useState( shippingInfo.postalCode  || '' )
    const [ phoneNo,     setPhoneNo     ] = useState( shippingInfo.phoneNo     || '' )
    const [ country,     setCountry     ] = useState( shippingInfo.country     || '' )    
    const [ countryCode, setCountryCode ] = useState( shippingInfo.countryCode || '' )    

    const submitHandler = (e) => {

        e.preventDefault()

        dispatch(saveShippingInfo( { name, email, address, city, subDistrict, district, postalCode, phoneNo, country, countryCode } ))

        setIsDetailsOpen(false)  
        setIsConfirmOpen(true)        
    }

    return ( 
        <> 
        <button 
            className="icon-button" 
            aria-label="back"
            onClick={handleBack}
            style={{ margin: '5px 0 0 5px' }}
        >

            <ArrowBackIcon />

        </button>   

        <h3 className="wrapper" style={{ paddingBottom: 0 }}>Shipping</h3>

        <CheckoutSteps details />

        <p className="cart-banner">
            <GlobeIcon style={{ color: 'white' }} />
            International Shipping
        </p>
          
        <form className="wrapper" onSubmit={submitHandler}>
       
            <div className="mobile-parent" style={{ gap: 2, marginBottom: 2 }}>
            
                <Input
                    type="text"
                    placeholder="Full name *"
                    value={name} 
                    onChange={(e) => setName(e.target.value)} 
                    filled={name}
                    required
                /> 

                <Input
                    type="text"
                    placeholder="Email *"
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                    filled={email}
                    required
                /> 

            </div>

            <div className="mobile-parent" style={{ gap: 2, marginBottom: 2 }}>

                <Input
                    type="text"
                    placeholder="House no./street *"
                    value={address} 
                    onChange={(e) => setAddress(e.target.value)} 
                    filled={address}
                    required
                /> 

                <Input
                    type="text"
                    placeholder="City *"
                    value={city} 
                    onChange={(e) => setCity(e.target.value)} 
                    filled={city}
                    required
                /> 

            </div>

            <div className="mobile-parent" style={{ gap: 2, marginBottom: 2 }}>

                <Input
                    type="text"
                    placeholder="Sub-district *"
                    value={subDistrict} 
                    onChange={(e) => setSubDistrict(e.target.value)} 
                    filled={subDistrict}
                    required
                /> 

                <Input
                    type="text"
                    placeholder="District *"
                    value={district} 
                    onChange={(e) => setDistrict(e.target.value)} 
                    filled={district}
                    required
                /> 

            </div>

            <div className="mobile-parent" style={{ gap: 2, marginBottom: 2 }}>
           
                <Input
                    type="text"
                    placeholder="Post code *"
                    value={postalCode} 
                    onChange={(e) => setPostalCode(e.target.value.toUpperCase())} 
                    filled={postalCode}
                    required
                /> 

                <Input
                    type="text"
                    placeholder="Telephone *"
                    value={phoneNo} 
                    onChange={(e) => setPhoneNo(e.target.value)} 
                    filled={phoneNo}
                    required
                />                 
                
            </div> 

            <CountrySelect 
                country={country}
                setCountry={setCountry}
                countryCode={countryCode}
                setCountryCode={setCountryCode}
                filled={country}
                required
            />

            <button
                className="button"
                type="submit" 
                disabled={!name || !email || !address || !city || !subDistrict || !district || !phoneNo || !postalCode || !country ? true : false}
                style={{  marginTop: 40 }}
            >
                <SendIcon style={{ color: 'white' }} />
                &nbsp;
                Continue
            </button>                                

        </form>                
        </>
    )

}

export default Details