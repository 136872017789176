import {
    CREATE_MERCH_ORDER_REQUEST,
    CREATE_MERCH_ORDER_SUCCESS,
    CREATE_MERCH_ORDER_RESET,
    CREATE_MERCH_ORDER_FAIL,
    MY_MERCH_ORDERS_REQUEST,
    MY_MERCH_ORDERS_SUCCESS,
    MY_MERCH_ORDERS_FAIL,
    ALL_MERCH_ORDERS_REQUEST,
    ALL_MERCH_ORDERS_SUCCESS,
    ALL_MERCH_ORDERS_FAIL,
    UPDATE_MERCH_ORDER_REQUEST,
    UPDATE_MERCH_ORDER_SUCCESS,
    UPDATE_MERCH_ORDER_RESET,
    UPDATE_MERCH_ORDER_FAIL,
    DELETE_MERCH_ORDER_REQUEST,
    DELETE_MERCH_ORDER_SUCCESS,
    DELETE_MERCH_ORDER_RESET,
    DELETE_MERCH_ORDER_FAIL,
    MERCH_ORDER_DETAILS_REQUEST,
    MERCH_ORDER_DETAILS_SUCCESS,
    MERCH_ORDER_DETAILS_FAIL,
    CLEAR_ERRORS
} from '../constants/merchOrderConstants'

export const newMerchOrderReducer = (state = {}, action) => {

    switch (action.type) {

        case CREATE_MERCH_ORDER_REQUEST:
            return {
                ...state,
                loading: true
            }

        case CREATE_MERCH_ORDER_SUCCESS:
            return {
                loading: false,
                order: action.payload
            }

        case CREATE_MERCH_ORDER_RESET:
            return {
                ...state,
                loading: false,
                order: null
            }

        case CREATE_MERCH_ORDER_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }

}

export const myMerchOrdersReducer = (state = {orders: []}, action) => {

    switch (action.type) {

        case MY_MERCH_ORDERS_REQUEST:
            return {                
                loading: true
            }

        case MY_MERCH_ORDERS_SUCCESS:
            return {
                loading: false,
                orders: action.payload
            }

        case MY_MERCH_ORDERS_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }

}

export const merchOrderDetailsReducer = (state = { order: {} }, action) => {
    switch (action.type) {

        case MERCH_ORDER_DETAILS_REQUEST:
            return {
                loading: true
            }

        case MERCH_ORDER_DETAILS_SUCCESS:
            return {
                loading: false,
                order: action.payload
            }

        case MERCH_ORDER_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

export const allMerchOrdersReducer = (state = { orders: [] }, action) => {
    switch (action.type) {

        case ALL_MERCH_ORDERS_REQUEST:
            return {
                loading: true
            }

        case ALL_MERCH_ORDERS_SUCCESS:
            return {
                loading: false,
                orders: action.payload.orders,
                totalAmount: action.payload.totalAmount,
                pending: action.payload.pending
            }

        case ALL_MERCH_ORDERS_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

export const merchOrderReducer = ( state = {}, action ) => {
    switch (action.type) {

        case UPDATE_MERCH_ORDER_REQUEST:
        case DELETE_MERCH_ORDER_REQUEST:
            return {
                ...state,
                loading: true
            }
         
         case UPDATE_MERCH_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload
            } 
            
        case DELETE_MERCH_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload
            } 
        
        case UPDATE_MERCH_ORDER_FAIL:
        case DELETE_MERCH_ORDER_FAIL:
                return {
                    ...state,
                    error: action.payload
                }
        
        case UPDATE_MERCH_ORDER_RESET:
            return {
                ...state,
                isUpdated: false
            }

        case DELETE_MERCH_ORDER_RESET:
            return {
                ...state,
                isDeleted: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}