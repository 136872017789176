import { createStore, combineReducers, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'

import { tripDetailsReducer, tripDetailDetailsReducer, adminTripDetailDetailsReducer, newTripDetailReducer, tripDetailReducer } from './reducers/tripDetailsReducers'
import { productsReducer, productDetailsReducer, adminProductDetailsReducer, newProductReducer, productReducer } from './reducers/productReducers'
import { merchsReducer, merchDetailsReducer, adminMerchDetailsReducer, newMerchReducer, merchReducer } from './reducers/merchReducers'
import { boatsReducer, boatDetailsReducer, adminBoatDetailsReducer, newBoatReducer, boatReducer } from './reducers/boatReducers'
import { diveSitesReducer, diveSiteDetailsReducer, adminDiveSiteDetailsReducer, newDiveSiteReducer, diveSiteReducer } from './reducers/diveSiteReducers'
import { authReducer, userReducer, forgotPasswordReducer, allUsersdReducer, userDetailsReducer } from './reducers/userReducers'
import { newOrderReducer, myOrdersReducer, orderDetailsReducer, allOrdersReducer, orderReducer } from './reducers/orderReducers'
import { randomReviewsReducer, allPendingReviewsReducer, allAdminReviewsReducer, adminReviewDetailsReducer, allReviewsReducer,  newReviewReducer, reviewReducer } from './reducers/reviewReducers'
import { coursesReducer, courseDetailsReducer, adminCourseDetailsReducer, newCourseReducer, courseReducer } from './reducers/courseReducers'
import { categoriesReducer, adminCategoriesReducer, newCategoryReducer, categoryReducer, categoryDetailsReducer } from './reducers/categoryReducers'
import { newMerchOrderReducer, myMerchOrdersReducer, merchOrderDetailsReducer, allMerchOrdersReducer, merchOrderReducer } from './reducers/merchOrderReducers'
import { pagesReducer, pageDetailsReducer, adminPageDetailsReducer, newPageReducer, pageReducer } from './reducers/pageReducers'
import { newHomepageReducer, homepageReducer, homepageDetailsReducer } from './reducers/homepageReducers'
import { cartReducer, merchCartReducer } from './reducers/cartReducers'

const reducer = combineReducers({    

    auth: authReducer,
    user: userReducer,
    allUsers: allUsersdReducer,
    userDetails: userDetailsReducer,
    forgotPassword: forgotPasswordReducer,

    boats: boatsReducer,    
    boatDetails: boatDetailsReducer,
    adminBoatDetails: adminBoatDetailsReducer,    
    newBoat: newBoatReducer,
    boat: boatReducer,

    diveSites: diveSitesReducer,    
    diveSiteDetails: diveSiteDetailsReducer,
    adminDiveSiteDetails: adminDiveSiteDetailsReducer,    
    newDiveSite: newDiveSiteReducer,
    diveSite: diveSiteReducer,

    products: productsReducer,    
    productDetails: productDetailsReducer,
    adminProductDetails: adminProductDetailsReducer,    
    newProduct: newProductReducer,
    product: productReducer,

    tripDetails: tripDetailsReducer,    
    tripDetailDetails: tripDetailDetailsReducer,
    adminTripDetailDetails: adminTripDetailDetailsReducer,    
    newTripDetail: newTripDetailReducer,
    tripDetail: tripDetailReducer,
   
    cart: cartReducer,
    merchCart: merchCartReducer,

    newOrder: newOrderReducer,
    myOrders: myOrdersReducer,
    allOrders: allOrdersReducer,
    orderDetails: orderDetailsReducer,
    order: orderReducer,

    randomReviews: randomReviewsReducer, 
    adminReviews : allAdminReviewsReducer,
    pendingReviews: allPendingReviewsReducer,
    allReviews: allReviewsReducer,
    reviewDetails: adminReviewDetailsReducer,
    review: reviewReducer,
    newReview: newReviewReducer,

    courses: coursesReducer,   
    courseDetails: courseDetailsReducer,
    adminCourseDetails: adminCourseDetailsReducer,    
    newCourse: newCourseReducer,
    course: courseReducer,

    merchs: merchsReducer,    
    merchDetails: merchDetailsReducer,
    adminMerchDetails: adminMerchDetailsReducer,    
    newMerch: newMerchReducer,
    merch: merchReducer,

    categories: categoriesReducer,
    adminCategories: adminCategoriesReducer,
    newCategory: newCategoryReducer,
    category: categoryReducer,
    categoryDetails: categoryDetailsReducer,

    newMerchOrder: newMerchOrderReducer,
    myMerchOrders: myMerchOrdersReducer,
    allMerchOrders: allMerchOrdersReducer,
    merchOrderDetails: merchOrderDetailsReducer,
    merchOrder: merchOrderReducer,

    pages: pagesReducer, 
    page: pageReducer,   
    pageDetails: pageDetailsReducer,
    adminPageDetails: adminPageDetailsReducer,    
    newPage: newPageReducer, 

    homepage: homepageReducer,   
    homepageDetails: homepageDetailsReducer,
    newHomepage: newHomepageReducer, 
    
})

let initialState = {
    cart: {
        cartItems: localStorage.getItem('cartItems') 
            ? JSON.parse(localStorage.getItem('cartItems'))
            : [],     
        bookingInfo: localStorage.getItem('bookingInfo') 
            ? JSON.parse(localStorage.getItem('bookingInfo'))
            : {},
        daytripInfo: localStorage.getItem('daytripInfo') 
            ? JSON.parse(localStorage.getItem('daytripInfo'))
            : {},    
        courseInfo: localStorage.getItem('courseInfo') 
            ? JSON.parse(localStorage.getItem('courseInfo'))
            : {}    
    },
    merchCart: {
        merchCartItems: localStorage.getItem('merchCartItems') 
        ? JSON.parse(localStorage.getItem('merchCartItems'))
        : [],
        shippingInfo: localStorage.getItem('shippingInfo') 
            ? JSON.parse(localStorage.getItem('shippingInfo'))
            : {}
    }
}

const middleware = [thunk]
const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store