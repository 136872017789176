export const ADMIN_DIVE_SITES_REQUEST = 'ADMIN_DIVE_SITES_REQUEST'
export const ADMIN_DIVE_SITES_SUCCESS = 'ADMIN_DIVE_SITES_SUCCESS'
export const ADMIN_DIVE_SITES_FAIL = 'ADMIN_DIVE_SITES_FAIL'

export const ALL_DIVE_SITES_REQUEST = 'ALL_DIVE_SITES_REQUEST'
export const ALL_DIVE_SITES_SUCCESS = 'ALL_DIVE_SITES_SUCCESS'
export const ALL_DIVE_SITES_FAIL = 'ALL_DIVE_SITES_FAIL'

export const NEW_DIVE_SITE_REQUEST = 'NEW_DIVE_SITE_REQUEST'
export const NEW_DIVE_SITE_SUCCESS = 'NEW_DIVE_SITE_SUCCESS'
export const NEW_DIVE_SITE_RESET = 'NEW_DIVE_SITE_RESET'
export const NEW_DIVE_SITE_FAIL = 'NEW_DIVE_SITE_FAIL'

export const DELETE_DIVE_SITE_REQUEST = 'DELETE_DIVE_SITE_REQUEST'
export const DELETE_DIVE_SITE_SUCCESS = 'DELETE_DIVE_SITE_SUCCESS'
export const DELETE_DIVE_SITE_RESET = 'DELETE_DIVE_SITE_RESET'
export const DELETE_DIVE_SITE_FAIL = 'DELETE_DIVE_SITE_FAIL'

export const UPDATE_DIVE_SITE_REQUEST = 'UPDATE_DIVE_SITE_REQUEST'
export const UPDATE_DIVE_SITE_SUCCESS = 'UPDATE_DIVE_SITE_SUCCESS'
export const UPDATE_DIVE_SITE_RESET = 'UPDATE_DIVE_SITE_RESET'
export const UPDATE_DIVE_SITE_FAIL = 'UPDATE_DIVE_SITE_FAIL'

export const DIVE_SITE_DETAILS_REQUEST = 'DIVE_SITE_DETAILS_REQUEST'
export const DIVE_SITE_DETAILS_SUCCESS = 'DIVE_SITE_DETAILS_SUCCESS'
export const DIVE_SITE_DETAILS_FAIL = 'DIVE_SITE_DETAILS_FAIL'

export const ADMIN_DIVE_SITE_DETAILS_REQUEST = 'ADMIN_DIVE_SITE_DETAILS_REQUEST'
export const ADMIN_DIVE_SITE_DETAILS_SUCCESS = 'ADMIN_DIVE_SITE_DETAILS_SUCCESS'
export const ADMIN_DIVE_SITE_DETAILS_FAIL = 'ADMIN_DIVE_SITE_DETAILS_FAIL'

export const DELETE_IMAGE_REQUEST = 'DELETE_IMAGE_REQUEST'
export const DELETE_IMAGE_SUCCESS = 'DELETE_IMAGE_SUCCESS'
export const DELETE_IMAGE_RESET = 'DELETE_IMAGE_RESET'
export const DELETE_IMAGE_FAIL = 'DELETE_IMAGE_FAIL'

export const UPDATE_IMAGE_REQUEST = 'UPDATE_IMAGE_REQUEST'
export const UPDATE_IMAGE_SUCCESS = 'UPDATE_IMAGE_SUCCESS'
export const UPDATE_IMAGE_RESET = 'UPDATE_IMAGE_RESET'
export const UPDATE_IMAGE_FAIL = 'UPDATE_IMAGE_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'