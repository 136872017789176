import { useEffect, useState } from 'react'
import TextFieldsIcon from '../icons/text/TextFieldsIcon'
import ToolTip from './ToolTip'

const TextSwitcher = () => {

    const [ largeText, setLargeText ] = useState(localStorage.getItem('largeText'))

    useEffect(() => {
        let mode = localStorage.getItem('largeText') 
        if (mode) {            
            setLargeText(true)
            document.body.classList.add('largetext')
        } else {
            setLargeText(false)           
        }          
    }, [])

    const handleText = () => {
        if (!largeText) {
            setLargeText(true)
            document.body.classList.add('largetext')
            localStorage.setItem('largeText', 'enabled')
        } else {
            setLargeText(false)
            document.body.classList.remove('largetext')
            localStorage.removeItem('largeText')
        }       
    }

    return (  
        <div style={{
                position: 'fixed', 
                bottom: 0,
            }}
        >

        
        <ToolTip 
            title={largeText ? 'Original text' : 'Large text'} 
            className="tooltip-bottom-left"
        >

            <span 
                onClick={handleText}
                style={{                     
                    background: 'var(--secondary-color)',
                    borderRadius: '50%',
                    padding: 6,
                    width: 48,
                    height: 48,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer'

                }}
            >            

                <TextFieldsIcon style={{ color: 'white' }} />            

            </span>  

        </ToolTip>   

        </div>   

    )

}

export default TextSwitcher