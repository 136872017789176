const PassportIcon = ({ style, aria }) => {

    return (

        <svg style={style} aria-label={aria} className="icon" viewBox="0 0 430 430">      
            <g>
                <g>
                    <g>
                        <polygon points="281,0 126.263,60 281,60    "/>
                        <path d="M85,76v354h260V76H85z M310.5,152c0,4.418-3.582,8-8,8h-71c-4.418,0-8-3.582-8-8c0-4.418,3.582-8,8-8h71     C306.918,144,310.5,147.582,310.5,152z M260.151,281H223v-82.495C242.647,205.947,258.321,240.126,260.151,281z M153.5,113h123     c4.418,0,8,3.582,8,8c0,4.418-3.582,8-8,8h-123c-4.419,0-8-3.582-8-8C145.5,116.582,149.081,113,153.5,113z M115.319,297h38.509     c1.536,38.644,14.136,71.068,32.413,87.796C147.483,373.178,118.618,338.693,115.319,297z M115.318,281     c1.949-24.628,12.813-46.743,29.382-63.111c11.47-11.35,25.678-19.932,41.542-24.687c-18.278,16.728-30.878,49.152-32.415,87.798     H115.318z M207,379.495c-19.647-7.442-35.32-41.621-37.151-82.495H207V379.495z M207,281h-37.151     c1.831-40.874,17.504-75.053,37.151-82.495V281z M199.5,160h-72c-4.419,0-8-3.582-8-8c0-4.418,3.581-8,8-8h72     c4.418,0,8,3.582,8,8C207.5,156.418,203.918,160,199.5,160z M223,379.495V297h37.152     C258.321,337.874,242.647,372.053,223,379.495z M243.76,384.796c18.277-16.728,30.877-49.152,32.413-87.796h38.508     C311.382,338.693,282.517,373.178,243.76,384.796z M276.173,281c-1.536-38.646-14.137-71.07-32.415-87.798     c15.864,4.755,30.072,13.337,41.542,24.687c16.569,16.368,27.433,38.484,29.382,63.111H276.173z"/>
                    </g>
                </g>
            </g>
        </svg>

        

    )

}

export default PassportIcon