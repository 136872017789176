import { useState } from 'react'
import Backdrop from '../tools/Backdrop'
import ArrowDropDownIcon from '../icons/arrows/ArrowDropDownIcon'
import ArrowDropUpIcon from '../icons/arrows/ArrowDropUpIcon'
import './forms.css'

function Select({ type, func, style, className, label, data, value, valueTwo, onChange, onChangeTwo, onChangeThree, filled, required, bottom, disabled }) {  

    const [ isOpen, setIsOpen ] = useState(false)  

    const handleSelectChange = (e) => {
        onChange(e.target.value) 
        if (func) func(e)
        setIsOpen(false)   
    }

    const handleMultiSelectChange = (e) => {
        onChange(e.target[0].value)
        onChangeTwo(e.target[1].value)   
        if (onChangeThree) onChangeThree(e.target[2].value)
        if (func) func(e)     
        setIsOpen(false)    
    }

    const toggleDropdown = () => {
        if (!disabled) setIsOpen(!isOpen)        
    }  

    return (
        <>
        {isOpen && (
            <Backdrop
                invisible
                onclick={toggleDropdown}
            /> 
        )}

        <div style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}>        
            <div 
                className={`select-container relative`}
                style={{ pointerEvents: disabled ? 'none' : 'auto' }}
            >
                <div 
                    className={`label ${isOpen ? 'open' : ''}`} 
                    onClick={toggleDropdown}  
                >
                    <div 
                        tabIndex={0} 
                        className={`cursor-pointer input focusable-div ${className ? className : null}`}
                        style={required 
                            ? { 
                            background: filled 
                                ? 'var(--cta-discount)' 
                                : required
                                    ? 'var(--cta-required)'
                                    : 'transparent'
                            } : style}
                    >

                        <div className="d-flex align-items-center">    
                            {type === 'shipping' && (
                                <img 
                                    src={valueTwo} 
                                    alt={value} 
                                    style={{ width: 20, marginRight: 10 }}
                                />
                            )}                         
                            {value || value === 0 ? value : label}
                        </div>
                        
                    </div>               

                    <small>{label}</small>
                    
                    <span className="adornment-end">                   
                        {isOpen 
                            ? <ArrowDropUpIcon style={{ color: 'var(--cta-red)' }} />
                            : <ArrowDropDownIcon style={{ color: 'var(--cta-green)' }} />}                                                        
                    </span>

                </div>

                {isOpen && !onChangeTwo && (                
                    <ul className={`select-dropdown ${bottom ?  'bottom' : null} ${className ? className : null}`}>
                        {data && data.map((item, index) => (
                            <li 
                                key={index} 
                                onClick={() => handleSelectChange({ target: { value: item.value } })}
                            >
                                {item.label}
                            </li>
                        ))}
                    </ul>
                )}

                {isOpen && onChangeTwo && type !== 'shipping' && (                
                    <ul className="select-dropdown">
                        {data && data.map((item, index) => (
                            <li 
                                key={index} 
                                onClick={() => handleMultiSelectChange({ target: [{ value: item.value }, { value: item.id }] })}
                            >
                                {item.label}
                            </li>
                        ))}
                    </ul>
                )}

                {isOpen && type === 'shipping' && (
                    <ul className="select-dropdown">
                        {data && data.map((item, index) => (
                            <li 
                                key={index} 
                                className="d-flex align-items-center"
                                onClick={() => handleMultiSelectChange({ target: [{ value: item.value }, { value: item.iconUrl }, { value: item.trackingLink }] })}
                            >
                                <img 
                                    src={item.iconUrl} 
                                    alt={item.label} 
                                    style={{ width: 20, marginRight: 10 }}
                                />
                                {item.label}
                            </li>
                        ))}
                    </ul>
                )}
            </div>  
        </div>     
        </>
    )
}

export default Select
