import WhatsAppIcon from '../icons/social/WhatsAppIcon'

const WhatsAppButton = () => {
  const phoneNumber = '+66924962287'
  const message = 'Hello, I have a question!'

  const openWhatsAppChat = () => {
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank')
  }

  return (
    <span 
      className="d-flex align-items-center"
      style={{ 
        position: 'fixed', 
        bottom: 0, 
        right: 0, 
        zIndex: 2, 
        color: 'var(--secondary-color)',
        background: 'white',
        paddingLeft: 10,
        borderTopLeftRadius: 10 
    }}
    >
      Chat
      &nbsp; 
      <button className="icon-button" aria-label="WhatsApp Chat" onClick={openWhatsAppChat}>
        <WhatsAppIcon style={{ fontSize: '2rem' }} />       
      </button>    
    </span>
  )
}

export default WhatsAppButton
