import { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { login, clearErrors } from '../../actions/userActions'
import Loader from '../layout/tools/Loader'
import Input from '../layout/forms/Input'
import PersonAddAltIcon from '../layout/icons/people/PersonAddAltIcon'
import LoginIcon from '../layout/icons/login/LoginIcon'
import KeyIcon from '../layout/icons/login/KeyIcon'
import { isPasswordValid, validateEmail } from '../../utils'

const Login = ({ setIsLoginVisible, setIsRegisterVisible, setIsForgotVisible, redirect, setRedirect }) => {
        
    const alert    = useAlert()
    const dispatch = useDispatch()
    
    const { loading, isAuthenticated, error } = useSelector( state => state.auth )  

    const [ email,    setEmail    ] = useState('')
    const [ password, setPassword ] = useState('')  
  
    useEffect(() => {        

        if(isAuthenticated) {            
            alert.success('Logged in')
            if (setRedirect) setRedirect(redirect)
            setIsLoginVisible(false)
        }      
        if(error) { 
            if (error === 'Email does not exist' || error === 'Incorrect password') {
                alert.error(error)
            }            
            dispatch(clearErrors())
        }        
    }, [dispatch, setIsLoginVisible, redirect, setRedirect, alert, isAuthenticated, error])
    
    const submitHandler = (e) => {  
        e.preventDefault()      
        dispatch(login(email, password))
    }  

    const handleRegister = () => {
        setIsLoginVisible(false)
        setIsRegisterVisible(true)
    }

    const handleForgot = () => {
        setIsLoginVisible(false)
        setIsForgotVisible(true)
    }

    return (  

        <form className="wrapper" onSubmit={submitHandler}>

            <h3>Login</h3>  

            {loading ? <Loader hideProgress/> : (  
                <>
                <p style={{ marginBottom: 40, fontSize: 12 }}>
                    Please LOGIN or 
                    &nbsp;
                    <span className="link" onClick={handleRegister}>create an account</span> 
                    &nbsp;
                    to continue. 
                </p>                         

                <div className="d-flex direction-column gap-2">                         

                    <Input 
                        placeholder="Email"
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                        filled={validateEmail(email)}
                        autoCapitalize="none"
                        required
                        autoFocus
                    />  
                    
                    <Input 
                        type="password"
                        placeholder="Password"
                        value={password}                             
                        onChange={(e) => setPassword(e.target.value)} 
                        filled={isPasswordValid(password)}
                        required
                    /> 

                </div>         

                <button 
                    type="submit"                             
                    className="button booking-button gap-1"
                    disabled={!validateEmail(email) || !isPasswordValid(password)}
                >
                    <LoginIcon style={{ color: 'white' }} /> Login
                </button>                        

                <div className="d-flex justify-content-between align-items-center"> 

                    <small className="d-flex align-items-center cursor-pointer"  onClick={handleForgot}>
                        <button className="icon-button">
                            <KeyIcon />
                        </button> 
                        &nbsp; 
                        Forgot Password?  
                    </small>

                    <small className="d-flex align-items-center cursor-pointer" onClick={handleRegister}>
                        New User?
                        &nbsp; 
                        <button className="icon-button">
                            <PersonAddAltIcon />
                        </button> 
                    </small>

                </div>   
                </>
            )}                

        </form>
        
    )

}

export default Login