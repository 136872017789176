export const ALL_COURSES_REQUEST = 'ALL_COURSES_REQUEST'
export const ALL_COURSES_SUCCESS = 'ALL_COURSES_SUCCESS'
export const ALL_COURSES_FAIL = 'ALL_COURSES_FAIL'

export const ADMIN_COURSES_REQUEST = 'ADMIN_COURSES_REQUEST'
export const ADMIN_COURSES_SUCCESS = 'ADMIN_COURSES_SUCCESS'
export const ADMIN_COURSES_FAIL = 'ADMIN_COURSES_FAIL'

export const COURSE_DETAILS_REQUEST = 'COURSE_DETAILS_REQUEST'
export const COURSE_DETAILS_SUCCESS = 'COURSE_DETAILS_SUCCESS'
export const COURSE_DETAILS_FAIL = 'COURSE_DETAILS_FAIL'

export const ADMIN_COURSE_DETAILS_REQUEST = 'ADMIN_COURSE_DETAILS_REQUEST'
export const ADMIN_COURSE_DETAILS_SUCCESS = 'ADMIN_COURSE_DETAILS_SUCCESS'
export const ADMIN_COURSE_DETAILS_FAIL = 'ADMIN_COURSE_DETAILS_FAIL'

export const NEW_COURSE_REQUEST = 'NEW_COURSE_REQUEST'
export const NEW_COURSE_SUCCESS = 'NEW_COURSE_SUCCESS'
export const NEW_COURSE_RESET = 'NEW_COURSE_RESET'
export const NEW_COURSE_FAIL = 'NEW_COURSE_FAIL'

export const UPDATE_COURSE_REQUEST = 'UPDATE_COURSE_REQUEST'
export const UPDATE_COURSE_SUCCESS = 'UPDATE_COURSE_SUCCESS'
export const UPDATE_COURSE_RESET = 'UPDATE_COURSE_RESET'
export const UPDATE_COURSE_FAIL = 'UPDATE_COURSE_FAIL'

export const DELETE_COURSE_REQUEST = 'DELETE_COURSE_REQUEST'
export const DELETE_COURSE_SUCCESS = 'DELETE_COURSE_SUCCESS'
export const DELETE_COURSE_RESET = 'DELETE_COURSE_RESET'
export const DELETE_COURSE_FAIL = 'DELETE_COURSE_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'
