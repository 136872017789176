import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { getBoats } from '../../../actions/boatActions'
import { getCourses } from '../../../actions/courseActions'
import { useMediaQuery } from 'react-responsive'
import { cloudinaryURL } from '../../../utils'
import LoginIcon from '../icons/login/LoginIcon'
import ToolTip from '../tools/ToolTip'
import Modal from '../../modals/modal/Modal'
import Contact from '../../modals/Contact'
import MailIcon from '../icons/navigation/MailIcon'
import MenuIcon from '../icons/navigation/MenuIcon'
import MenuOpenIcon from '../icons/navigation/MenuOpenIcon'
import DirectionsBoatIcon from '../icons/navigation/DirectionsBoatIcon'
import Profile from '../../modals/Profile'
import Navigation from '../../modals/Navigation'
import Boats from '../../modals/Boats'
import DashboardNav from '../../modals/DashboardNav'
import Courses from '../../modals/Courses'
import Register from '../../modals/Register'
import Login from '../../modals/Login'
import WhatsAppButton from '../tools/WhatsAppButton'
import ForgotPassword from '../../modals/ForgotPassword'
import UpdatePassword from '../../modals/UpdatePassword'
import UpdateProfile from '../../modals/UpdateProfile'
import Destinations from '../../modals/Destinations'
import BuyNow from '../tools/BuyNow'
import './header.css'

const Header = () => {  

    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const isMobile = useMediaQuery({ query: `(max-width: 400px)` }) 

    const [ isMenuVisible,           setMenuVisible             ] = useState(false)
    const [ isNavVisible,            setIsNavVisible            ] = useState(false)
    const [ isBoatsVisible,          setIsBoatsVisible          ] = useState(false)
    const [ isDestinationsVisible,   setIsDestinationsVisible   ] = useState(false)
    const [ isCoursesVisible,        setIsCoursesVisible        ] = useState(false)
    const [ isModalVisible,          setIsModalVisible          ] = useState(false)    
    const [ isProfileVisible,        setIsProfileVisible        ] = useState(false)    
    const [ isSticky,                setIsSticky                ] = useState(false)
    const [ isClear,                 setIsClear                 ] = useState(false)       
    const [ isRegisterVisible,       setIsRegisterVisible       ] = useState(false)
    const [ isLoginVisible,          setIsLoginVisible          ] = useState(false)
    const [ isForgotVisible,         setIsForgotVisible         ] = useState(false)
    const [ isUpdatePasswordVisible, setIsUpdatePasswordVisible ] = useState(false)
    const [ isUpdateProfileVisible,  setIsUpdateProfileVisible  ] = useState(false)

    const { user           } = useSelector( state => state.auth  ) 
    const { cartItems      } = useSelector( state => state.cart  )     
    const { merchCartItems } = useSelector( state => state.merchCart  )     
    const { boats          } = useSelector( state => state.boats )
    const { courses        } = useSelector( state => state.courses )

    useEffect(() => {

        const handleScroll = () => {
           
            setIsSticky(window.scrollY >= window.innerHeight * 0.5 ? true : false)

        }    

        const checkIsClear = () => {
            const clearPages = ['/', '/merchandise', '/day-trip-information', '/course-success', '/course-payment', '/course-information', '/course-booking', '/day-trip-itinerary', '/payment', '/information', '/services', '/courses', '/similan-islands-liveaboard-reviews', '/boats', '/booking', '/day-trip-booking', '/trip-schedules', '/trip-pickups', '/equipment-rental', '/trip-list', '/trip-itinerary', '/booking-agent', '/trip-list-agent', '/confirm-agent', /^\/success-agent\/[\w]+/, '/day-trip-schedule', '/day-trip-success', /^\/trip\/[\w]+/, /^\/boat\/[\w]+/, /^\/page\/[\w]+/, /^\/course\/[\w]+/, /^\/dive-site\/[\w]+/, /^\/dive-sites\/[\w]+/, /^\/deferred-payment\/[\w]+/, /^\/success\/[\w]+/,  /^\/final-details\/[\w]+/]
            if (isSticky) {
                setIsClear(false)
            } else {
                setIsClear(clearPages.some(pattern => (pattern instanceof RegExp ? pattern.test(location.pathname) : pattern === location.pathname)));
            }
        }    
        handleScroll()
        checkIsClear()
    
        window.addEventListener('scroll', handleScroll)
    
        return () => {
          window.removeEventListener('scroll', handleScroll)
        }
    }, [location.pathname, isSticky])

    useEffect(() => {
        dispatch(getBoats())
        dispatch(getCourses())
    }, [dispatch])    

    const toggleMenu = () => {
        setMenuVisible(isMenuVisible => !isMenuVisible)
    }

    const toggleModal = () => {
        setIsModalVisible(!isModalVisible)
    } 

    const toggleNav = () => {
        setIsNavVisible(!isNavVisible)
    }

    const toggleLogin = () => {
        setIsLoginVisible(!isLoginVisible)
    }

    const toggleRegister = () => {
        setIsRegisterVisible(!isRegisterVisible)
    }

    const toggleForgot = () => {
        setIsForgotVisible(!isForgotVisible)
    }

    const toggleUpdatePassword = () => {
        setIsUpdatePasswordVisible(!isUpdatePasswordVisible)
    }

    const toggleUpdateProfile = () => {
        setIsUpdateProfileVisible(!isUpdateProfileVisible)
    }

    const toggleBoats = () => {
        setIsBoatsVisible(!isBoatsVisible)
    }  
    
    const toggleDestinations = () => {
        setIsDestinationsVisible(!isDestinationsVisible)
    }  

    const toggleCourses = () => {
        setIsCoursesVisible(!isCoursesVisible)
    }   

    const toggleProfile = () => {
        setIsProfileVisible(!isProfileVisible)
    }

    return (
        <>    
        <header className={isSticky ? 'sticky' : ''}>
                
            <nav className={`wrapper-x ${isClear ? 'clear' : 'background-panel'}`}> 

                {isClear && (
                    <div className="container link-patch">
                        <span onClick={() => navigate('/')} />
                    </div>
                )}                

                <div>                   

                    {!isClear ? (
                         <Link to="/" className="d-flex align-items-center">
                            <button className="icon-button">
                                <img                    
                                    src={cloudinaryURL('https://res.cloudinary.com/dvzjiqoxr/image/upload/v1724651471/westcoast/logo/logo_zdamf7.png')}
                                    alt={`${process.env.REACT_APP_SITE_NAME} logo`}
                                    className="logo"
                                    loading="lazy" 
                                    width="190"
                                    height="190"                                       
                                />   
                            </button> 
                            {!isMobile && (
                                <>
                                 &nbsp;   
                                <b className="text-center">
                                    <small style={{ lineHeight: '1rem', display: 'block' }}>
                                        <span style={{ fontSize: 16 }}>W</span>
                                        est
                                        &nbsp; 
                                        <span style={{ fontSize: 16 }}>C</span>
                                        oast
                                        &nbsp;
                                        <span style={{ fontSize: 16 }}>D</span>
                                        ivers
                                    </small>
                                </b>    
                                </>
                            )}                                   
                        </Link> 
                    ) : ( 
                        null
                     )}       

                </div>            

                <div className="nav-icons">

                    <ToolTip title="Menu">
                        <button 
                            className="icon-button" 
                            aria-label="Menu"
                            onClick={toggleNav}
                        >
                            {isNavVisible ? <MenuOpenIcon aria="Menu open" /> : <MenuIcon aria="Menu" /> }                                   
                        </button>  
                    </ToolTip>  

                    <span 
                        style={{ 
                            position: merchCartItems.length ? 'relative' : 'absolute',
                            zIndex: merchCartItems.length ? 1 : -1,
                            opacity: merchCartItems.length ? 1 : 0,
                        }}
                    >
                    <ToolTip title="Cart">                            
                        <small className="icon-tag">
                            <b>{merchCartItems.length}</b>
                        </small>    
                        <BuyNow />
                    </ToolTip>  
                    </span>                                             

                    {cartItems.length ? (
                        <ToolTip title="Booking">  
                            <Link to={user?.role === 'agent' ? '/booking-agent' : '/booking'}>                                                                             
                                <small className="icon-tag">
                                    <b>{cartItems.length}</b>
                                </small>   
                                <button className="icon-button" aria-label="Cart">
                                    <DirectionsBoatIcon />
                                </button>
                            </Link>
                        </ToolTip>
                    ) : null}                    

                    <ToolTip title="Contact">
                        <button 
                            className="icon-button"
                            aria-label="Contact" 
                            onClick={toggleModal}
                        >                                    
                                <MailIcon />                                    
                        </button>  
                    </ToolTip>  

                    {user ? (   
                        <>
                        <div className="relative">  
                            <ToolTip 
                                className="tooltip-right"
                                title={`${user?.firstName} (${user?.role})`} 
                            >
                                <button 
                                    className="icon-button" 
                                    aria-label="Avatar"
                                    onClick={() => toggleMenu()}
                                >
                                    <img 
                                        alt={user?.firstName} 
                                        src={user.avatar?.url || '/images/default-avatar.jpg'}   
                                        style={{ width: '48px', height: '48px', borderRadius: '50%' }}                                    
                                    /> 
                                </button>
                            </ToolTip>                            
                        </div>                       
                    </>
                    ) : (
                        <ToolTip title="Login" className="tooltip-right">
                            <button 
                                className="icon-button" 
                                aria-label="Login"
                                onClick={toggleLogin}
                            >
                                <LoginIcon />
                            </button>
                        </ToolTip>    
                    )}
                </div>   
                
            </nav>   

            <WhatsAppButton />    
       
        </header>          

        <Modal
            isModalVisible={isModalVisible} 
            onBackdropClick={toggleModal}   
            content={ <Contact /> }
        />        

        <Modal
            isModalVisible={isMenuVisible} 
            onBackdropClick={toggleMenu}   
            content={ 
                <DashboardNav 
                    setMenuVisible={toggleMenu} 
                    setIsProfileVisible={setIsProfileVisible}
                /> 
        }
        />    

        <Modal
            isModalVisible={isNavVisible} 
            onBackdropClick={toggleNav}   
            content={ 
                <Navigation  
                    setIsNavVisible={setIsNavVisible}
                    setIsBoatsVisible={setIsBoatsVisible}
                    setIsCoursesVisible={setIsCoursesVisible}
                    setIsDestinationsVisible={setIsDestinationsVisible}  
                /> 
            }
        />   

        <Modal
            isModalVisible={isBoatsVisible} 
            onBackdropClick={toggleBoats}   
            content={ 
            <Boats  
                setIsNavVisible={setIsNavVisible}
                setIsBoatsVisible={setIsBoatsVisible}
                boats={boats}
            /> 
            }
        />    

        <Modal
            isModalVisible={isDestinationsVisible} 
            onBackdropClick={toggleDestinations}   
            content={ 
            <Destinations  
                setIsNavVisible={setIsNavVisible}
                setIsDestinationsVisible={setIsDestinationsVisible}
            /> 
            }
        />    

        <Modal
            isModalVisible={isCoursesVisible} 
            onBackdropClick={toggleCourses}   
            content={ 
            <Courses  
                setIsNavVisible={setIsNavVisible}
                setIsCoursesVisible={setIsCoursesVisible}
                courses={courses}
            /> 
            }
        />     

        <Modal
            isModalVisible={isRegisterVisible} 
            onBackdropClick={toggleRegister}   
            content={ 
            <Register  
                setIsRegisterVisible={setIsRegisterVisible}
                setIsLoginVisible={setIsLoginVisible}
            /> 
            }
        />     

        <Modal
            isModalVisible={isLoginVisible} 
            onBackdropClick={toggleLogin}   
            content={ 
            <Login  
                setIsLoginVisible={setIsLoginVisible}
                setIsRegisterVisible={setIsRegisterVisible}
                setIsForgotVisible={setIsForgotVisible}
            /> 
            }
        />  

        <Modal
            isModalVisible={isForgotVisible} 
            onBackdropClick={toggleForgot}   
            content={ 
            <ForgotPassword  
                setIsForgotVisible={setIsForgotVisible}
                setIsLoginVisible={setIsLoginVisible}
            /> 
            }
        />  

        <Modal
            isModalVisible={isUpdatePasswordVisible} 
            onBackdropClick={toggleUpdatePassword}   
            content={ 
            <UpdatePassword  
                setIsUpdatePasswordVisible={setIsUpdatePasswordVisible}
                setIsProfileVisible={setIsProfileVisible}
                setIsUpdateProfileVisible={setIsUpdateProfileVisible }
            /> 
            }
        />  

        <Modal
            isModalVisible={isUpdateProfileVisible} 
            onBackdropClick={toggleUpdateProfile}   
            content={ 
            <UpdateProfile 
                setIsUpdatePasswordVisible={setIsUpdatePasswordVisible}
                setIsUpdateProfileVisible={setIsUpdateProfileVisible}
                setIsProfileVisible={setIsProfileVisible}
            /> 
            }
        />  

        <Modal
            isModalVisible={isProfileVisible} 
            onBackdropClick={toggleProfile}   
            content={ 
            <Profile  
                setIsProfileVisible={setIsProfileVisible} 
                setIsUpdatePasswordVisible={setIsUpdatePasswordVisible}
                setIsUpdateProfileVisible={setIsUpdateProfileVisible} 
            /> 
            }   
        />    
        </>
    )

}

export default Header