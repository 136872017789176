import CheckIcon from "../../layout/icons/actions/CheckIcon"
import './checkoutSteps.css'

const CheckoutSteps = ({ details, confirm, payment }) => {
   
    return (
        <div className="stepper-container-merch">

            <div className="stepper">

                <span className="step active">
                    {!details 
                        ? <CheckIcon style={{ fontSize: '1.2rem' }} />
                        : '1'
                    }
                </span>

                <span className="line" />

                <span className={`step ${confirm || payment ? 'active' : ''}`}>
                    {confirm || details 
                        ? "2" 
                        : <CheckIcon style={{ fontSize: '1.2rem' }} />
                    }
                </span>           

                <span className="line" />

                <span className={`step ${payment ? 'active' : ''}`}>                   
                    3
                </span>            

            </div>

            <div className="stepper">
                
                <small style={{ color: details ? 'orange' : '#ccc' }}>Shipping</small>
            
                <small style={{ color: confirm ? 'orange' : '#ccc' }}>Confirm</small>
            
                <small style={{ color: payment ? 'orange' : '#ccc' }}>Payment</small>
            
            </div>
        </div>
    )

}

export default CheckoutSteps