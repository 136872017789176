import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getPages } from '../../../actions/pageActions'
import { cloudinaryURL } from '../../../utils'
import ScrollToTop from 'react-scroll-to-top'
import CookieConsent from 'react-cookie-consent'
import ArrowCircleUpIcon from '../icons/arrows/ArrowCircleUpIcon'
import FacebookRoundedIcon from '../icons/social/FacebookRoundedIcon'
import YouTubeIcon from '../icons/social/YouTubeIcon'
import InstagramIcon from '../icons/social/InstagramIcon'
import ToolTip from '../tools/ToolTip'
import Loader from '../tools/Loader'
import CircleIcon from '../icons/lists/CircleIcon'
import TextSwitcher from '../tools/TextSwitcher'
import './footer.css'

const Footer = () => {  

    const dispatch = useDispatch()

    const { loading, pages } = useSelector( state => state.pages )

    useEffect(() => {
        dispatch(getPages())
    }, [dispatch])

    return (
        <>
        <footer> 

            {loading ? <Loader /> : (
                <>
                <div className="container wrapper">    
                
                    <div style={{ margin: '40px 0' }}>

                        <h3 style={{ color: '#ccc' }}>Follow Us</h3>

                        <div className="d-flex gap-2 justify-content-center" style={{ marginTop: 40 }}>

                            <ToolTip title="Facebook">
                                <a href="https://www.facebook.com/westcoastdiversliveaboards/" target="_blank" rel="noreferrer" aria-label="Facebook">
                                    <FacebookRoundedIcon style={{ fontSize: '3rem', color: 'var(--secondary-color)' }} />
                                </a>
                            </ToolTip>

                            <ToolTip title="YouTube">
                                <a href="https://www.youtube.com/@WestCoastLiveaboards" target="_blank" rel="noreferrer" aria-label="YouTube">
                                    <YouTubeIcon style={{ fontSize: '3rem', color: 'var(--secondary-color)' }} />
                                </a>
                            </ToolTip>
                            
                            <ToolTip title="Coming soon ...">
                                {/* <a href="https://www.facebook.com/" target="_blank" rel="noreferrer" aria-label="Instagram"> */}
                                    <InstagramIcon style={{ fontSize: '3rem', color: 'var(--secondary-color)' }} />
                                {/* </a> */}
                            </ToolTip>

                        </div>

                    </div>  

                    <h3 style={{ color: '#ccc' }}>Useful links</h3>

                    <ul className="footer-links">
                        {pages && pages.map((page, index) => (
                            <li key={index} className="d-flex align-items-center" style={{ gap: 10 }}>
                                <CircleIcon style={{ color: 'var(--secondary-color)', fontSize: '0.5rem' }} />
                                <Link to={`/page/${page.slug}`} className="d-flex align-items-center">
                                    <small>{page.name}</small>                                   
                                </Link>
                            </li>
                        ))}                  
                    </ul>

                    <h3 style={{ color: '#ccc', margin: '40px 0' }}>West Coast Divers</h3>

                    <div className="text-center">
                    <img 
                        src="https://res.cloudinary.com/dvzjiqoxr/image/upload/v1732629376/westcoast/emails/padi-logo_k2bfsx.png" 
                        alt="PADI logo" 
                        style={{ width: 32 }}
                    />
                    <br />
                    <h4 style={{ color: 'orange', marginTop: 0 }}>PADI Dive Boat R-6156</h4>

                    </div>

                    <table 
                        className="borderless footer-table" 
                        style={{ 
                            marginBottom: 40, 
                            tableLayout: 'fixed',
                            width: '100%' 
                        }}
                    >
                        <tbody>
                            <tr>
                                <th>Booking office:</th>
                                <td>162/60 Moo 10, Chaofa West Rd.</td>
                            </tr>
                            <tr>
                                <th>Main office:</th>
                                <td>89/8 Moo 5, Chaofa West Rd.</td>
                            </tr>
                            <tr>
                                <th />
                                <td>
                                    <p style={{ fontSize: 12 }}>                                
                                        T.Chalong, A.Muang
                                        <br />
                                        Phuket 83130 Thailand
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <th>Phone, WhatsApp, Line:</th>
                                <td>+66 (0) 92 496 2287</td>
                            </tr>
                            <tr>
                                <th>WeChat ID (English):</th>
                                <td>Westcoastdivers</td>
                            </tr>
                            <tr>
                                <th>WeChat ID (Chinese):</th>
                                <td>WestcoastdiverChina</td>
                            </tr>
                            <tr>
                                <th>Email:</th>
                                <td>info@westcoastdivers.com</td>
                            </tr>
                            <tr>
                                <th>Chinese email 中文服务:</th>
                                <td>chinesereservation@westcoastdivers.com</td>
                            </tr>                            
                        </tbody>
                    </table>                    

                    <div style={{ margin: '40px 0' }}>
                        <img                    
                            src={cloudinaryURL('https://res.cloudinary.com/dvzjiqoxr/image/upload/v1724650655/westcoast/logo/logo-gold_olkq0d.png')}
                            alt={`${process.env.REACT_APP_SITE_NAME} logo`}
                            className="logo"
                            loading="lazy"  
                            width="160"
                            height="147"  
                            style={{ width: 160 }}                                    
                        />   
                    </div>                    

                    <div style={{ marginTop: 40 }}>   
                        <small style={{ lineHeight: '2rem' }}>
                            Copyright 
                            &copy;
                            &nbsp;
                            <span style={{ color: 'orange' }}>{process.env.REACT_APP_SITE_NAME}</span> 
                            &nbsp;
                            {new Date().getFullYear()}
                            . All Rights Reserved.
                        </small>
                    </div>

                </div> 
            
                <CookieConsent
                    debug={
                        process.env.REACT_APP_NODE_ENV === 'DEVELOPMENT' 
                            ? true 
                            : false
                    }
                    buttonStyle={{ 
                        textTransform:'none', 
                        backgroundColor: "var(--primary-color)", 
                        color: "white", 
                        padding: '9px 15px',
                        borderRadius: 8 
                    }}
                    buttonText="Ok, got it"
                    ariaAcceptLabel="Ok, got it"
                    expires={365}
                    style={{ 
                        background: "var(--background)", 
                        color: '#303030',
                        boxShadow: '0px -1px 15px 10px rgba(0,0,0,0.1',
                    }}
                >
                    <p>This site uses cookies. See our&nbsp;
                        <Link style={{ textDecoration: "underline" }} to="/page/privacy-policy">privacy policy</Link> for details.

                    </p>                    
                </CookieConsent> 
                </>
            )}  

        </footer>

        <ScrollToTop 
            smooth 
            top={500}
            style={{ right: 0, top: '50%', transform: 'translateY(-50%)', borderRadius: '5px 0 0 5px' }} 
            component={<ArrowCircleUpIcon style={{ fontSize: '3rem', color: 'var(--secondary-color)', textShadow: '2px 2px 4px #fff'}} />} 
        />

        <TextSwitcher />
        </>
    )
    
}

export default Footer
