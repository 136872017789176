import LinearProgress from '../forms/LinearProgress'
import CircularProgress from './circularProgress/CircularProgress'

const Loader = ({ style, className, hideProgress }) => {

    return (

        <>
        {!hideProgress && <LinearProgress />}        

        <div 
            className={`loader ${className ? className : ''}`} 
            style={style}
        > 

           <CircularProgress />

        </div>
        </>

    )
    
}

export default Loader