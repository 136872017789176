import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAlert } from 'react-alert'
import { forgotPassword, clearErrors } from '../../actions/userActions'
import ReCAPTCHA from 'react-google-recaptcha'
import Loader from '../layout/tools/Loader'
import Input from '../layout/forms/Input'
import LoginIcon from '../layout/icons/login/LoginIcon'
import KeyIcon from '../layout/icons/login/KeyIcon'
import { validateEmail } from '../../utils'

const ForgotPassword = ({ setIsLoginVisible, setIsForgotVisible }) => {

    const { loading, message, error } = useSelector( state => state.forgotPassword )

    const alert    = useAlert()
    const dispatch = useDispatch()
    
    const [ email,    setEmail    ] = useState('')    
    const [ captcha,  setCaptcha  ] = useState(false)

    useEffect(() => {    
        if(error) { 
            alert.error(error)
            dispatch(clearErrors())            
        }
        if(message) {
            alert.success(message)   
            setIsForgotVisible(false) 
        }        
    }, [dispatch, setIsForgotVisible, alert, message, error])   

    const submitHandler = (e) => {
        e.preventDefault()  
        window.scroll(0,0)        
        setCaptcha(true)                  
    }

    const handleLogin = () => {
        setIsLoginVisible(true)
        setIsForgotVisible(false)
    }

    const handleChange = (value) => {  
        const formData = new FormData()
        formData.set('email', email)
        formData.set('key', value)   
        dispatch(forgotPassword(formData))
        setCaptcha(false)          
    }   

    return (
        <>  
        {captcha ? (
            <>
            {loading && <Loader />}  
            <div className="wrapper">                    
                <h3>Security</h3>
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={handleChange}
                />                    
            </div>
            </>  
        ): (          
            <>
            {loading ? <Loader /> : (                            

                <form className="wrapper" onSubmit={submitHandler}>

                    <h3>Forgot Password</h3> 

                    <Input 
                        placeholder="Email"
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                        filled={validateEmail(email)}
                        autoCapitalize="none"
                        required
                        autoFocus
                    />   

                    <button 
                        type="submit"                             
                        className="button booking-button gap-1"
                        disabled={!validateEmail(email)}
                    >
                        <KeyIcon style={{ color: 'white' }} /> Request New Password
                    </button>                          

                    <div className="d-flex justify-content-between align-items-center">
                        <small>Already signed up?</small>
                        <small className="d-flex align-items-center cursor-pointer"  onClick={handleLogin}>
                            Login
                            &nbsp;
                            <button className="icon-button">
                                <LoginIcon />
                            </button>
                        </small>                        
                    </div>                       

                </form>

            )}
            </>
        )}   
        </>
    )

}

export default ForgotPassword
